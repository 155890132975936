// Colors

// transition-duration
$trds: 0.25s;

$breakpoints: (
  "phone"       : 400px,
  "phone-wide"  : 480px,
  "phablet"     : 560px,
  "tablet-small": 640px,
  "tablet"      : 768px,
  "tablet-wide" : 1024px,
  "desktop"     : 1248px,
  "desktop-wide": 1440px
);

$content-size: 1262px;

// Fonts