footer {
	background: var(--text);
	padding-top: 61px;
	padding-bottom: 76px;
	color: var(--white);

	@include mobile {
		padding-top: 46px; } }

.footer__info {
	width: 33%;

	@include tablet {
		width: 100%; } }

.footer__logo {
	padding: 16px 8px;

	svg {
		fill: var(--white);
		width: 66px;
		height: 88px; } }

.footer__oferta {
	font-size: 14px;
	line-height: 130%;

	p + p {
		margin-top: 12px; } }

.footer__soc {
	display: flex;
	align-items: center;
	margin-top: 11px;

	@include mobile {
		margin-top: 44px; } }

.footer-soc__title {
	font-size: 16px;
	margin-right: 12px;

	@include tablet {
		margin-right: 24px; } }

.footer-soc__list {
	display: flex;
	align-items: center;
	gap: 24px; }

.footer-soc__link.footer-soc__link--wa {

	&:hover {

		svg {
			fill: var(--main); } }

	svg {
		width: 32px;
		height: 32px;
		fill: var(--white);
		transition-duration: var(--trds);

		@include tablet {
			width: 44px;
			height: 44px; } } }

.footer-soc__link.footer-soc__link--tg {

	&:hover {

		svg {
			fill: var(--main); } }

	svg {
		width: 32px;
		height: 32px;
		_stroke: var(--white);
		_stroke-width: 3px;
		fill: var(--white);
		transition-duration: var(--trds);

		@include tablet {
			width: 44px;
			height: 44px; } } }

.footer__top {
	display: flex;
	justify-content: space-between;
	gap: 53px;

	@include tablet {
		flex-direction: column; }

	@include mobile {
		gap: 73px; } }

.footer__nav {
	display: flex;
	flex: 1 0;
	justify-content: space-between;
	margin-top: -4px;
	gap: 10px;

	@include tablet {
		flex-direction: column;
		margin-top: 0; }

	@include tablet {
		gap: 16px; } }

.footer-nav__list {
	position: relative;

	@include tablet {

		&::after {
			position: absolute;
			content: '';
			left: -13px;
			right: -13px;
			height: 1px;
			background: var(--white);

			@include mobile {
				left: -20px;
				right: -20px; } } } }

.footer-nav__title {
	font-weight: 500;
	font-size: 32px;
	line-height: 100%;
	color: #fff;
	border-bottom: 1px solid var(--white);
	font-family: var(--font-add);
	padding-bottom: 8px;
	margin-bottom: 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&.open {

		.footer-nav__arrow {
			transform: translateY(3px) rotate(180deg); } }

	@include tablet {
		font-weight: 500;
		font-size: 30px;
		line-height: 100%;
		border-bottom: none;
		cursor: pointer;
		margin-bottom: 0; } }

.footer-nav__arrow {
	margin-left: 15px;
	margin-bottom: 5px;
	display: none;
	_transition-duration: var(--trds);

	@include tablet {
		display: block; }

	svg {
		width: 32px;
		height: 32px;
		stroke: var(--white);
		stroke-width: 2px; } }

.footer__list {
	display: flex;
	flex-direction: column;
	gap: 16px;

	@include tablet {
		display: none; } }

.footer__item {

	@include tablet {
		margin-bottom: 15px; } }

.footer__link {
	color: var(--white);
	font-weight: 700;
	font-size: 16px;
	text-decoration: none;
	line-height: normal;
	transition-duration: var(--trds);

	&:hover {
		color: var(--main); } }

.footer__address {
	display: flex;
	flex-direction: column;
	gap: 16px;

	@include tablet {
		display: none; } }

.footer__addres {
	margin-bottom: 20px; }

.footer-address__list {
	display: flex;
	flex-direction: column;
	gap: 8px; }

.footer-address__link {
	font-size: 14px;
	line-height: 130%;
	color: var(--white);
	text-decoration: none;
	transition-duration: var(--trds);

	&:hover {
		color: var(--main); } }

.footer-address__title {
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 12px; }

.footer__bottom {
	border-top: 1px solid var(--white);
	padding-top: 30px;
	padding-bottom: 17px;
	margin-top: 30px;

	@include tablet {
		border: none;
		margin-top: 20px;
		padding-bottom: 20px; } }

.footer-copy__list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	font-size: 16px;
	line-height: 130%;

	@include tablet {
		flex-direction: column;
		align-items: flex-start;
		gap: 24px; } }

.footer-copy__link {
	color: var(--white);
	text-decoration: none;
	transition-duration: var(--trds);

	&:hover {
		color: var(--main); } }
