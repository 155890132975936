 :root {
	--main: #8B658A;
	--main-hover: #905a8f;
	--main-active: #935491;
	--white: #FFFFFF;
	--text: #343633;
	--black: #000000;
	--gray: #858585;
	--background: #F3F0F3;
	--font-main: 'Circe';
	--font-add: 'Cormorant Unicase';
	--trds: 0.5s; }

@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
	height: 100%;
	background: var(--white);
	_scroll-behavior: smooth; }

body {
	font: 400 15px/130% var(--font-main);
	color: var(--text);
	text-rendering: optimizeSpeed;
	min-width: 300px;
	background: var(--background);

	&.page--inner {
		background: var(--white); }

	@include tablet {
		line-height: 100%; } }

*,
*::before,
*::after {
	box-sizing: border-box; }

ul,
ol {
	list-style: none;
	padding: 0; }

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0; }

a:not([class]) {
	text-decoration-skip-ink: auto; }

img {
	_max-width: 100%;
	_display: block; }

input,
button,
textarea,
select {
	font: inherit;
	width: 100%; }

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
	vertical-align: middle; }

textarea {
	resize: none; }

section {
	position: relative; }

input,
select,
button {
	outline: none; }

.d-f {
	display: flex; }

.a-c {
	align-items: center; }

.j-c {
	justify-content: center; }

a {
	color: var(--black);
	transition-duration: $trds;

	&:hover {
		text-decoration: none; } }

.color--orange {
	color: var(--orange) !important; }

.color--white {
	color: var(--white) !important; }

.button, .head_banner_button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 40px;
	font-weight: 700;
	font-size: 17px;
	line-height: normal;
	color: var(--white);
	text-decoration: none;
	transition-duration: $trds;
	white-space: nowrap;
	cursor: pointer;
	background: var(--main);
	border: 1px solid var(--main);
	border-radius: 10px;

	@include tablet {
		padding-top: 9px;
		padding-bottom: 9px; }

	&:hover {
		background: var(--main-hover);
		border-color: var(--main-hover); }

	&:active {
		background: var(--main-active);
		border-color: var(--main-active); }

	&:disabled {
		opacity: .5;
		cursor: default; } }

.button--transparent {
	background: transparent;
	color: var(--text);

	&:hover {
		background: rgba(139, 101, 138, 0.2); }

	&:active {
		background: rgba(139, 101, 138, 0.5); }

	&:disabled {
		opacity: .5;
		cursor: default; } }

.button--white {
	background: transparent;
	color: var(--white);
	border-color: var(--white);

	&:hover {
		background: rgba(255, 255, 255, 0.2);
		border-color: var(--white); }

	&:active {
		background: rgba(255, 255, 255, 0.5);
		border-color: var(--white); }

	&:disabled {
		opacity: .5;
		cursor: default; } }

.overflow {
	overflow: hidden; }

.input {
	border: 1px solid #CECECE;
	border-radius: 3px;
	padding-left: 25px;
	padding-right: 25px; }

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%; }

.content {
	flex: 1 0 auto;
	padding: 50px 0;
	background: transparent;

	@include tablet {
		padding: 40px 0; }

	table {
		border-spacing: 0;
		border: 0; }

	p {
		margin-bottom: 20px; } }

h1, .h1 {
	font-weight: 500;
	font-size: 54px;
	line-height: 100%;
	margin-bottom: 40px;
	font-family: var(--font-add);

	@include mobile {
		font-size: 30px;
		margin-bottom: 20px; }

	span {
		color: var(--main); } }

p + h2, p + .h2 {
	margin-top: 20px; }

h2, .h2 {
	font-weight: 500;
	font-size: 32px;
	line-height: normal;
	margin-bottom: 20px;
	font-family: var(--font-add);

	span {
		color: var(--main); } }

h3 {
	text-transform: none;
	font-weight: 700;
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 20px;

	@include tablet {
		font-size: 16px;
		margin-bottom: 20px; } }


.container-fluid {
	margin: 0 auto;
	max-width: $content-size;
	width: 100%;

	@include mobile {
		padding: 0 20px !important; } }

.section {
	padding: 50px 0;
	border-bottom: 1px solid #C1C1C1;

	@include tablet {
		padding: 40px 0; } }

.section__title {
	font-size: 54px;
	line-height: 100%;
	text-align: center;
	color: var(--main);
	font-family: var(--font-add);

	@include tablet; }

.section__desc {
	text-align: center;
	font-size: 15px;
	line-height: 21px;
	margin-bottom: 30px;

	@include tablet {
		font-size: 15px;
		line-height: 19px; } }

.tab__section {

	.tabs {
		display: flex;
		justify-content: center;
		width: 100%;
		max-width: 450px;
		margin: 0 auto;
		justify-content: space-between;
		margin-bottom: 50px;
		border-bottom: 1px solid #C1C1C1;

		@include tablet {
			margin-bottom: 30px; } }

	.tabs__link {
		width: 220px;
		min-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 13px;
		line-height: 19px;
		text-align: center;
		color: #393939;
		text-decoration: none;
		margin-bottom: -1px;
		padding: 10px;

		&.active {
			background: var(--blue);
			color: #fff; } }

	.tab {

		& > div {
			display: none;

			&.active {
				display: block; } } } }

.soc__float {
	backdrop-filter: blur(10px);
	background: rgba(243, 240, 243, 0.5);
	border-radius: 10px;
	padding: 16px;
	position: fixed;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	z-index: 3;
	box-shadow: 0 0 10px rgba(0,0,0,.075);

	svg {
		width: 30px;
		height: 30px; } }

.soc-float__link {
	display: block;
	padding: 5px;
	padding-bottom: 16px;

	& + .soc-float__link {
		border-top: 1px solid var(--white);
		padding-top: 16px; }

	&:first-child {
		padding-top: 2px; }

	&:last-child {
		padding-bottom: 2px; } }

.popup {
	opacity: 0;
	visibility: hidden;
	z-index: -5;
	transition-duration: $trds;

	&.open {
		opacity: 1;
		visibility: visible; } }

.hidden {
	display: none; }

.popup {
	position: fixed;
	width: 95%;
	max-width: 505px;
	z-index: 101;
	top: 50%;
	border-radius: 10px;
	left: 50%;
	transform: translate(-50%,-50%);
	background: #FFFFFF;
	box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
	overflow: hidden;
	padding: 32px;

	@include mobile {
		padding: 32px 17px; }

	.form {
		padding: 0;

		.form__row {
			flex-direction: column; }

		.button {
			cursor: pointer;
			min-height: 58px;

			@include mobile {
				min-height: 48px; } }

		.button[disabled] {
			cursor: default;
			opacity: .75;

			&:hover {
				background: var(--main); } }

		.form__input {
			border: 1px solid var(--gray);
			color: var(--black); }

		.form-label__input--checkbox--info {
			color: var(--black); } } }

.popup__title {
	height: 70px;
	background: var(--blue);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	font-weight: 400; }

.popup__close {
	position: absolute;
	width: 25px;
	height: 25px;
	right: 10px;
	top: 10px;

	svg {
		width: 25px;
		height: 25px; } }



.form {
	border-radius: 10px;
	padding: 24px 32px 22px;
	background: var(--white);
	width: 100%;
	position: relative;

	@include mobile {
		padding-top: 31px;
		padding-left: 17px;
		padding-right: 17px;
		padding-bottom: 16px; }

	.select2-container {
		width: 100% !important;

		&.select2-container--open {

			.select2-selection--single {

				.select2-selection__rendered {}

				.select2-selection__arrow {
					transform: rotate(180deg); } } }

		.select2-selection--single {
			border: 1px solid var(--gray);
			border-radius: 10px;
			padding: 16px 24px;
			height: 58px;

			@include tablet {
				height: 48px;
				border: 1px solid var(--main); }

			.select2-selection__rendered {
				padding: 0;
				font-size: 16px;
				line-height: 130%;
				color: var(--gray);

				@include mobile {
					line-height: 98%; } }

			.select2-selection__arrow {
				width: 26px;
				height: 26px;
				top: 16px;
				right: 24px;
				transition-duration: var(--trds);
				background: url('data:image/svg+xml,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="13" fill="white" /><path d="M13.3738 16.8398L18.8574 11.0847C18.9489 10.9886 19 10.8611 19 10.7284C19 10.5957 18.9489 10.4682 18.8574 10.3721L18.8512 10.3659C18.8068 10.3192 18.7534 10.282 18.6942 10.2566C18.635 10.2312 18.5712 10.2181 18.5068 10.2181C18.4424 10.2181 18.3786 10.2312 18.3194 10.2566C18.2602 10.282 18.2068 10.3192 18.1624 10.3659L12.999 15.7855L7.83761 10.3659C7.79323 10.3192 7.73981 10.282 7.68061 10.2566C7.6214 10.2312 7.55764 10.2181 7.49321 10.2181C7.42877 10.2181 7.36502 10.2312 7.30581 10.2566C7.2466 10.282 7.19318 10.3192 7.14881 10.3659L7.14261 10.3721C7.05107 10.4682 7 10.5957 7 10.7284C7 10.8611 7.05107 10.9886 7.14261 11.0847L12.6262 16.8398C12.6744 16.8904 12.7324 16.9307 12.7967 16.9583C12.8609 16.9858 12.9301 17 13 17C13.0699 17 13.1391 16.9858 13.2034 16.9583C13.2676 16.9307 13.3256 16.8904 13.3738 16.8398Z" fill="%238B658A" /></svg>') no-repeat 50% 50%;

				@include mobile {
					top: 10px; }

				b {
					display: none; } } } } }

span.select2-dropdown {
	border-radius: 10px;
	background:  #f8f8f8;
	display: flex;
	flex-direction: column;
	border: 1px solid var(--main);
	border-top: none;
	overflow: hidden;

	@include mobile {
		border: 1px solid var(--main); } }

li.select2-results__option {
	padding: 16px 24px;
	display: block;
	color: var(--text);

	&:first-child {
		display: none; } }

span.select2-container--default {

	.select2-results__option--highlighted.select2-results__option--selectable {
		background: var(--white);
		color: var(--gray); }

	.select2-results__option--selected {
		background: var(--white);
		color: var(--gray); } }

.form__title {
	color: var(--main);
	margin-bottom: 32px;
	line-height: 100%;

	@include tablet {
		font-size: 24px;
		margin-bottom: 33px; }

	@include mobile {
		text-align: center; } }

.form__fieldset {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 0;
	margin: 0;
	border: none;

	@include tablet {
		gap: 24px; } }

.form__label {
	display: block; }

.form__input {
	width: 100%;
	height: 54px;
	border: 1px solid var(--gray);
	border-radius: 10px;
	padding: 16px 24px;
	transition-duration: var(--trds);

	@include tablet {
		height: 48px; }

	&:hover, &:focus {
		border: 1px solid var(--main);
		background: #F3F0F3;
		color: var(--text); }

	&:disabled {
		opacity: .5;
		cursor: default; }

	&.error {
		border: 1px solid #ff0303 !important;
		color: #ff0303 !important;

		@include placeholder {
			color: #ff0303 !important; } } }

.form__label--checkbox {
	display: flex;
	margin-top: 11px; }

.form__input--checkbox {
	position: absolute;
	z-index: -5;
	width: auto;
	opacity: 0;

	&:checked + .form__input--checkbox--info {

		&::before {
			background: var(--main) url('data:image/svg+xml,<svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.1381 0.426593C16.4392 0.711196 16.6149 1.10371 16.6266 1.51782C16.6383 1.93193 16.4851 2.33375 16.2006 2.63493L7.34644 12.0099C7.20294 12.1616 7.0305 12.283 6.8393 12.3669C6.6481 12.4508 6.44202 12.4955 6.23325 12.4984C6.02447 12.5013 5.81723 12.4624 5.62376 12.3838C5.4303 12.3053 5.25453 12.1888 5.10686 12.0412L0.419358 7.35368C0.143358 7.05748 -0.00689869 6.66572 0.000243431 6.26092C0.00738555 5.85613 0.171369 5.46991 0.457646 5.18363C0.743923 4.89735 1.13014 4.73337 1.53494 4.72623C1.93973 4.71909 2.33149 4.86934 2.62769 5.14534L6.17977 8.69534L13.9298 0.489093C14.2144 0.188046 14.6069 0.0123464 15.021 0.000626132C15.4351 -0.0110941 15.8369 0.142124 16.1381 0.426593Z" fill="white" /></svg>') 50% 50% no-repeat;
			background-size: 12px 8px; } } }

.form__input--checkbox--info {
	font-size: 12px;
	line-height: 130%;
	line-height: 17px;
	padding-left: 24px;
	position: relative;
	cursor: pointer;
	user-select: none;
	display: flex;
	align-items: center;
	min-height: 16px;
	display: block;
	color: var(--gray);

	a {
		text-decoration: none;
		color: var(--gray); }

	&:hover {

		&:before {
			border: 1px solid var(--main); } }

	&::before {
		content: '';
		width: 16px;
		height: 16px;
		display: block;
		border: 1px solid #858585;
		position: absolute;
		top: 0;
		left: 0; } }

.form__warning {
	font-size: 12px;
	line-height: 130%;
	color: var(--gray);
	margin-top: 11px;
	margin-bottom: 0; }




.form__success {
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition-duration: 0.5s;
	border-radius: 10px;

	&.open {
		display: flex;
		opacity: 1;
		visibility: visible; }

	svg, img {
		width: 100px;
		height: 100px; } }


.form-success__title {
	font-weight: 700;
	font-size: 32px;
	line-height: 48px;
	text-align: center;
	color: #000000;
	margin-bottom: 35px;
	margin: 25px 0;

	@include mobile {
		font-size: 22px;
		margin-bottom: 20px;
		line-height: 22px; } }

.overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	backdrop-filter: blur(10px);
	background: rgba(0, 0, 0, 0.3);
	display: none; }

.nav__mobile {
	position: fixed;
	z-index: 101;
	left: 0;
	top: 0px;
	bottom: 0;
	width: 100%;
	max-width: 500px;
	max-width: 380px;
	background: #fff;
	transform: translateX(-100%);
	transition-duration: $trds;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	text-align: left;
	overflow-x: hidden;

	@include scrollbars(6px,var(--gray), var(--white));

	&.open {
		transform: translateX(0%); } }

.nav-mobile__top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%; }

.nav-mobile__logo {
	padding: 10px 20px;

	svg {
		width: 114px;
		height: 55px; } }

.nav-mobile__close {
	display: block;
	position: absolute;
	right: 38px;
	top: 19px;

	svg {
		width: 36px;
		height: 36px; } }

.nav-mobile__search {
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;

	.seach-form__input {
		margin: 2px 0 0 8px; }

	.search-block__form {
		border: 1px solid var(--gray);
		padding: 10px 35px 10px 35px; }

	.search-block__button {

		svg {
			width: 20px;
			height: 20px;
			stroke: var(--gray); } } }

.nav-mobile__contacts {
	padding-left: 20px;
	padding-right: 20px;
	width: 100%; }

.nav-mobile__button {
	width: 100%;
	height: 48px;
	width: 100%;
	margin-top: 24px; }

.nav-mobile__soc {
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 24px;
	padding-bottom: 24px; }

.mobile-soc__title {
	font-size: 18px;
	margin-right: 15px;
	font-weight: 700; }

.mobile-soc__list {
	display: flex;
	align-items: center;
	gap: 16px; }

.mobile-soc__link.mobile-soc__link--wa {

	&:hover {

		svg {
			fill: var(--main); } }

	svg {
		width: 32px;
		height: 32px;
		fill: var(--text);
		transition-duration: var(--trds); } }

.mobile-soc__link.mobile-soc__link--tg {

	&:hover {

		svg {
			fill: var(--main); } }

	svg {
		width: 32px;
		height: 32px;
		transition-duration: var(--trds); } }

.nav-mobile__list {
	list-style: none;
	padding: 0;
	margin: 0;
	padding: 0 20px;
	width: 100%; }

.nav-mobile__item {
	border-bottom: 1px solid var(--text);
	padding: 12px 20px 11px;

	&.parent {

		// &:hover

		// 	.nav-mobile__list-childs
		// 		display: block

		.footer-nav__arrow {
			display: block;
			width: 32px;
			height: 32px;
			position: absolute;
			right: 0;
			margin: 0;
			top: -5px; }

		svg {
			width: 22px;
			height: 22px;
			stroke: var(--main);
			// margin-left: 4px
 } }			// margin-bottom: 6px

	& + .nav-mobile__item {}

	&.open {
		padding-bottom: 24px;

		.footer-nav__arrow {
			transform: translateY(0) rotate(180deg); }

		.nav-mobile__link {
			margin-bottom: 19px; } } }

.nav-mobile__link {
	font-weight: 400;
	font-size: 20px;
	line-height: normal;
	color: var(--text);
	text-decoration: none;
	display: flex;
	position: relative;

	&:hover {
		color: var(--main); } }

.childs--open {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0px;
	top: 6px;
	transition-duration: var(--trds);

	&.active {
		transform: rotate(90deg); } }

.nav-mobile__list--services {
	margin-top: 10px;

	.nav-mobile__link {
		font-weight: 700;
		padding: 12px 20px 11px; }

	& > .nav-mobile__child-item {
		padding: 0;

		& > .nav-mobile__child-list {
			padding-bottom: 12px;

			& > .nav-mobile__item {
				padding-top: 0;
				padding-bottom: 0;
				border: none; } } } }

.nav-mobile__dropmenu {
	position: absolute;
	width: 300px;
	background: #1A1C23;
	top: 50px;
	padding: 20px;
	height: 100%;
	left: 100%;
	transition-duration: $trds;

	&.open {
		left: 0; } }

.nav-mobile__child-list {
	display: flex;
	flex-direction: column;
	gap: 10px;
	display: none;

	.nav-mobile__link {
		font-weight: 400;
		font-size: 18px;
		line-height: 130%;
		padding: 12px 0; }

	.nav-mobile__child-item {
		padding-top: 0;
		padding-bottom: 0;
		border: none; }

	.childs--open {
		right: -20px; }

	.nav-mobile__child-list {
		_margin-bottom: 12px;
		padding-left: 14px;
		margin-left: -14px;

		.nav-mobile__item {
			border: none;
			padding: 5px 0;
			position: relative;

			&::before {
				content: '';
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: #858585;
				position: absolute;
				left: -14px;
				top: 10px; }

			a.nav-mobile__link {
				text-decoration: none;
				color: #858585;
				font-size: 16px;
				padding: 0;

				&:hover {
					color: var(--main);
					text-decoration: underline; } } } } }


.nav-mobile__child-item {

	& + .nav-mobile__child-item {
		_margin-top: 10px; } }

.nav-mobile__child-link {
	font-size: 18px;
	line-height: 100%;
	text-decoration: none; }

.nav__contacts {
	padding: 0 20px;
	width: 100%; }

.nav__phone, .nav__mail {
	display: flex;
	align-items: center;

	svg, img {
		margin-right: 10px; } }

.nav-phone__link, .nav-mail__link {
	color: var(--black);
	font-size: 25px;
	text-decoration: none; }

.nav__mail {
	margin-top: 20px; }

.nav__button {
	margin-top: 30px;
	color: var(--blue);
	border-color: var(--blue);
	width: 100%;

	&:hover {
		background: var(--blue);
		color: var(--white); } }

.bread__mobile {
	align-items: center;
	display: none;

	&.show {
		display: flex; } }

.bread-mobile__link {
	font-size: 13px;
	line-height: 15px;
	color: #FFFFFF;
	text-decoration: none;
	display: flex;
	align-items: center;

	&.bread-mobile__home {

		svg {
			width: 20px;
			height: 20px; } } }

.nav-mobile__list-childs {
	display: none;
	background: #F2F2F2;
	width: calc(100% + 72px);
	margin: 0 -36px;
	padding: 0 36px; }

.nav-mobile-child__link {
	text-decoration: none;
	color: var(--white);
	font-size: 15px;
	line-height: 18px;

	&:hover {
		text-decoration: underline; } }

.nav-mobile-child__item {

	& + .nav-mobile-child__item {
		margin-top: 15px; }

	& + .nav-mobile-child__item--title {
		margin-top: 30px; } }

.nav-mobile-child__item--title {
	border-bottom: 1px solid #424349;
	margin-bottom: 15px;
	padding-bottom: 15px;

	.nav-mobile-child__link {
		font-size: 18px;
		line-height: 21px; } }

.slick-slider {

	.slick-next:before, .slick-prev:before {
		display: none; }

	.slick-slide {

		img {
			width: 100%; } }

	.slick-arrow {
		background: #D9D9D9;
		width: 56px;
		height: 56px;
		border-radius: 100%; }

	.slick-prev, .slick-next {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		z-index: 5;
		cursor: pointer;
		transition-duration: $trds; }

	.slick-prev {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 9L2 5.09756L7 1' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		left: -100px; }

	.slick-next {
		right: -100px;
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 4.90244L1 9' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E"); }

	.slick-prev:focus {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 9L2 5.09756L7 1' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-prev:hover {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 9L2 5.09756L7 1' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-next:focus {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 4.90244L1 9' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-next:hover {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 4.90244L1 9' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-dots {
		display: flex;
		justify-content: center;
		bottom: 0px;
		flex-wrap: wrap;
		margin-bottom: 0;

		@include tablet {
			bottom: -38px; }

		li {
			padding: 0;
			margin: 0;
			width: 60px;
			height: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 5px 10px;
			border-radius: 4px;

			@include tablet {
				width: 50px; }

			&.slick-active, &:hover {

				button {
					background: var(--blue);
					width: 60px;
					height: 8px;

					@include tablet {
						width: 50px; } } }

			button {
				background: #DBDBDB;
				width: 60px;
				height: 8px;
				padding: 0;
				transition-duration: $trds;
				border-radius: 4px;

				@include tablet {
					width: 50px; } }

			button:before {
				display: none; } }

		li:before {
			display: none; } } }


//Модули
@import "modules/modules";
