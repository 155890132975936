@font-face {
    font-family: 'Cormorant Unicase';
    src: url('../fonts/CormorantUnicase-Medium.eot');
    src: url('../fonts/CormorantUnicase-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CormorantUnicase-Medium.woff') format('woff'),
    url('../fonts/CormorantUnicase-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Cormorant Unicase';
    src: url('../fonts/CormorantUnicase-Bold.eot');
    src: url('../fonts/CormorantUnicase-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CormorantUnicase-Bold.woff') format('woff'),
    url('../fonts/CormorantUnicase-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    }
@font-face {
    font-family: 'Circe';
    src: url('../fonts/Circe-Regular.eot');
    src: url('../fonts/Circe-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Circe-Regular.woff') format('woff'),
    url('../fonts/Circe-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Circe';
    src: url('../fonts/Circe-Bold.eot');
    src: url('../fonts/Circe-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Circe-Bold.woff') format('woff'),
    url('../fonts/Circe-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('../fonts/Circe-Light.eot');
    src: url('../fonts/Circe-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Circe-Light.woff') format('woff'),
    url('../fonts/Circe-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}