.page--index {

	.content {
		padding: 0; } }

.section__address {

	.container-fluid {
		padding-top: 24px;
		padding-bottom: 24px; } }

.address__list {
	display: flex;
	justify-content: center;

	@include mobile {
		flex-direction: column;
		padding: 23px 17px; } }

.addres__title {
	font-weight: 700;
	font-size: 20px;
	line-height: 92%;
	color: var(--main); }

.address__item {
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 23px 64px;
	border-right: 1px solid var(--main);
	text-align: center;

	@include mobile {
		padding: 24px;
		border-bottom: 1px solid var(--main);
		border-right: none;
		gap: 20px; }

	&:first-child {
		padding-left: 0;

		@include mobile {
			padding-left: 24px; } }

	&:last-child {
		padding-right: 0;
		border: none;

		@include mobile {
			padding-right: 24px; } } }

.address__phone {
	text-decoration: none; }

.sale__slider-wrap {

	.container-fluid {
		height: 100%;
		position: relative; }

	.slick-prev {
		display: none; }

	.sert-slider__arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
		pointer-events: none;

		@include mobile {
			display: none; } }

	.slider-arrow__next {
		width: 36px;
		height: 36px;
		border: 1px solid var(--white);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		right: 13px;
		top: 50%;
		margin-top: -18px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			background: var(--main);
			border-color: var(--main); }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--white);
			transition-duration: var(--trds); } } }

.sale__slider {

	.container-fluid {
		height: 100%; }

	.sale-slider__item {
		height: 423px;
		background-position: 50% 0%;
		background-size: cover;

		@include tablet {
			height: 312px; }

		&.slider--one {

			.sale-slider__content {
				width: 100%;
				max-width: 740px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-top: 14px;

				@include mobile {
					padding-top: 25px; } }

			h2 {
				line-height: 100%;
				margin-bottom: 45px;

				@include tablet {
					font-size: 30px;
					line-height: 100%;
					margin-bottom: 29px; } }

			.sale-slider__price {
				display: flex;
				align-items: flex-end;
				line-height: normal;
				margin-bottom: 32px; }

			.sale-slider__price-now {
				color: var(--main);
				font-weight: 700;
				font-size: 52px;
				line-height: 60px;

				@include tablet {
					font-size: 30px;
					line-height: 100%; } }

			.sale-slider__price-desc {
				font-size: 24px;
				font-weight: 700;
				margin-bottom: 8px;
				margin-left: 10px;

				@include tablet {
					font-size: 15px;
					margin-left: 2px;
					margin-bottom: 0; } }

			.sale-slider__price-old {
				font-size: 24px;
				font-weight: 300;
				margin-bottom: 6px;
				margin-left: 10px;
				position: relative;

				&::after {
					content: '';
					background: var(--text);
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					height: 1px; }

				@include tablet {
					font-size: 18px;
					margin-bottom: 0; } }

			.sale-slider__button {
				width: 100%;
				max-width: 428px; } }

		&.slider--two {

			.sale-slider__content-inner {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center; }

			.sale-slider__content {
				width: 100%;
				max-width: 740px;
				display: flex;
				padding-top: 14px;
				align-items: center;
				gap: 32px;

				@include mobile {
					flex-direction: column;
					align-items: flex-start;
					gap: 8px;

					.sale-slider__title {
						width: 100%; } } }

			h2 {
				line-height: 100%;
				margin-bottom: 45px;
				padding-right: 10px;

				@include tablet {
					font-size: 20px; }

				@include mobile {
					margin-bottom: 25px; }

				span {
					display: block;
					font-size: 70px;
					margin-top: 20px;

					@include tablet {
						font-size: 40px;
						margin-top: 10px; } } }

			.sale-slider__price {
				display: flex;
				align-items: flex-end;
				line-height: normal;
				margin-bottom: 32px; }

			.sale-slider__price-now {
				color: var(--main);
				font-weight: 700;
				font-size: 64px;
				line-height: 60px;

				@include tablet {
					font-size: 30px;
					line-height: 100%; } }

			.sale-slider__price-desc {
				font-size: 32px;
				font-weight: 700;
				margin-bottom: -8px;
				margin-left: 10px;

				@include tablet {
					font-size: 18px;
					margin-bottom: 0;
					margin-left: 2px; } }

			.sale-slider__price-old {
				font-size: 32px;
				font-weight: 300;
				margin-bottom: -8px;
				margin-left: 10px;
				position: relative;

				&::after {
					content: '';
					background: var(--text);
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					height: 1px; }

				@include tablet {
					font-size: 18px;
					margin-bottom: 0; } }


			.sale-slider__button {
				width: 100%;
				max-width: 676px;
				margin-top: 40px;

				@include mobile {
					margin-top: 16px; } } }

		&.slider--three {

			.sale-slider__content {
				width: 100%;
				max-width: 430px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-top: 14px; }

			h2 {
				margin-bottom: 15px;
				line-height: 100%;

				@include tablet {
					font-size: 30px;
					line-height: 100%; }

				@include mobile {
					width: 150px; } }

			p {
				font-size: 16px;
				font-weight: 500;
				line-height: normal;
				padding: 0;
				margin: 0;
				width: 360px;

				@include tablet {
					font-size: 18px; }

				@include mobile {
					width: 300px; }

				span {
					color: var(--main);
					font-weight: 700; } }

			.sale-slider__button {
				width: 100%;
				max-width: 428px;
				margin-top: 40px; } } }

	.sale-slider__button {

		@include mobile {
			background: transparent;
			color: var(--text);
			border-width: 2px;

			&:hover {
				background: rgba(139, 101, 138, 0.2); }

			&:active {
				background: rgba(139, 101, 138, 0.5); }

			&:disabled {
				opacity: .5;
				cursor: default; } } } }

.section__form {
	background: url(../images/content/main/form_bg.svg) no-repeat 50% 50% var(--main);
	background-size: cover;
	padding-top: 56px;
	padding-bottom: 56px;

	@include mobile {
		padding-top: 51px;
		padding-bottom: 48px; }

	.container-fluid {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include mobile {
			flex-direction: column; } }

	.section-form__title {
		font-size: 54px;
		line-height: 100%;
		font-family: var(--font-add);
		color:#c7bad1 {}
		padding-right: 40px;

		@include tablet {
			font-size: 30px; }

		@include mobile {
			padding-right: 0;
			width: 100%;
			margin-bottom: 16px; }

		span {
			font-weight: 700;
			color: var(--white);

			@include tablet {
				font-weight: 400;
				text-transform: uppercase; } } }

	.section-form__subtitle {
		font-family: var(--font-main);
		font-size: 15px;
		line-height: 130%;
		margin-top: 16px;
		width: 100%;
		max-width: 616px; } }

.section-form__form {
	width: 100%;
	max-width: 503px;

	@include mobile {
		max-width: 100%; } }

.section__works {
	padding-top: 62px;
	padding-bottom: 62px;

	@include mobile {
		padding-top: 48px;
		padding-bottom: 48px; }

	.section__title {
		margin-bottom: 47px;

		@include tablet {
			font-size: 30px; } } }

.work__slider-wrap {
	margin-left: -13px;
	margin-right: -13px;
	position: relative;

	@include mobile {
		margin-left: -20px;
		margin-right: -20px; }

	.container-fluid {
		height: 100%;
		position: relative; }

	.slick-prev {
		display: none; }

	.sert-slider__arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
		pointer-events: none;

		@include mobile {
			display: none; } }

	.slider-arrow__prev {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		left: -48px;
		top: 50%;
		margin-top: -18px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		@include width1400 {
			_left: 25px; }

		&:hover {
			background: #8B658A;

			svg {
				fill: var(--white); } }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds);
			transform: rotate(180deg); } }

	.slider-arrow__next {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		right: -48px;
		top: 50%;
		margin-top: -18px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		@include width1400 {
			_right: 25px; }

		&:hover {
			background: #8B658A;

			svg {
				fill: var(--white); } }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds); } } }

.works-slider__img {
	border-radius: 20px;
	transition-duration: var(--trds);

	@include mobile {
		object-fit: cover;
		width: 100%;
		height: 100%; } }

.works-slider__item {
	position: relative;
	padding-left: 13px;
	padding-right: 13px;

	@include mobile {
		width: 326px; } }

.works-slider__item-inner {
	position: relative;
	border-radius: 20px;
	overflow: hidden;

	@include mobile {
		aspect-ratio: 3/4; }

	&:hover {

		.works-slider__img {
			transform: scale(1.15); }

		.works-slider__info {
			transform: translateY(calc(0%)); }

		.works-slider__more {
			opacity: 1; }

		.works-slider__link {
			background: var(--main);

			svg {
				fill: var(--white); } } }

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, rgba(52, 54, 51, 0) 0%, #000 80.09%);
		opacity: .85; } }

.works-slider__info {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	padding-bottom: 29px;
	transform: translateY(calc(100% - 24px));
	transition-duration: var(--trds);
	text-align: center;

	@include mobile {
		transform: translateY(calc(0%)); } }

.works-slider__title {
	font-weight: 700;
	font-size: 16px;
	color: var(--white);
	margin-bottom: 10px;
	position: absolute;
	line-height: normal;
	bottom: 100%;
	width: 100%; }

.works-slider__more {
	color: var(--gray);
	text-decoration: none;
	transition-duration: var(--trds);
	opacity: 0;

	@include mobile {
		opacity: 1; } }

.works-slider__link {
	position: absolute;
	background: #fff;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	position: absolute;
	top: 26px;
	right: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition-duration: var(--trds);
	z-index: 2;

	@include mobile {
		background: var(--main); }

	svg {
		width: 17px;
		height: 14px;
		fill: var(--main);
		transition-duration: var(--trds);
		transform: rotate(-45deg);

		@include mobile {
			fill: var(--white); } } }

.section__services {
	padding-top: 62px;
	padding-bottom: 62px;
	background: url(../images/content/main/services2.jpg) no-repeat 50% 50%;
	background-size: cover;

	@include mobile {
		padding-top: 46px;
		padding-bottom: 47px;
		background: url(../images/content/main/services2.jpg) no-repeat 00% 50%;
		background-size: auto 100%; }

	.section__title {
		color: var(--white);
		line-height: 100%;
		margin-bottom: 46px;
		text-align: left;

		@include tablet {
			font-size: 30px; }

		@include mobile {
			text-align: center;
			margin-bottom: 32px; } } }

.page--inner {

	.section__services {
		background: url(../images/content/about/services_inner.jpg) no-repeat 50% 50%;
		background-size: cover;

		@include mobile {
			background: url(../images/content/about/services_inner.jpg) no-repeat 80% 50%; } } }

.services__list {
	width: 100%;
	max-width: 470px;
	display: flex;
	flex-direction: column;
	gap: 25px;

	@include tablet {
		gap: 16px; } }

.service__link {
	display: flex;
	align-items: center;
	padding: 18px 24px;
	font-weight: 700;
	font-size: 16px;
	color: var(--white);
	justify-content: space-between;
	line-height: normal;
	text-decoration: none;
	backdrop-filter: blur(10px);
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid #fff;
	border-radius: 10px;
	transition-duration: var(--trds);

	@include tablet {
		font-size: 18px;
		padding-top: 10px;
		padding-bottom: 10px; }

	&:hover {
		background: rgba(255, 255, 255, 0.3);

		.service-link__icon {
			background: var(--main);

			svg {
				fill: var(--white); } } }

	&:active {
		background: rgba(255, 255, 255, 0.5);

		.service-link__icon {
			background: var(--main);

			svg {
				fill: var(--white); } } } }

.service-link__icon {
	width: 26px;
	height: 26px;
	min-width: 26px;
	background: var(--white);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition-duration: var(--trds);
	margin: 0 5px;

	svg {
		width: 15px;
		height: 12px;
		transition-duration: var(--trds);
		fill: var(--main); } }

.section__about {
	padding-top: 62px;
	padding-bottom: 62px;
	background: url(../images/content/main/about_bg.svg) no-repeat 50% 50%  #fff;
	background-size: cover;

	@include mobile {
		padding-top: 49px;
		padding-bottom: 48px; }

	.section__title {
		text-align: left;
		margin-bottom: 33px;

		@include tablet {
			font-size: 30px;
			line-height: 100%; }

		@include mobile {
			text-align: center;
			margin-bottom: 23px; } } }

.about__text {
	width: 100%;
	max-width: 850px;

	@include mobile {
		max-width: 100%; }

	p + p {
		margin-top: 10px; } }

.about__write {
	font-weight: 700;
	font-size: 16px;
	color: var(--main);
	line-height: normal;
	padding-top: 26px;
	padding-bottom: 24px;

	@include mobile {
		padding-top: 18px;
		padding-bottom: 32px; } }

.about-address__list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 26px;
	margin-top: 34px; }

.address__slider-wrap {
	margin-left: -13px;
	margin-right: -13px;

	@include mobile {
		margin-left: -20px;
		margin-right: -20px; } }

.about-address__item {
	padding-left: 13px;
	padding-right: 13px;

	@include mobile {
		width: 326px; } }

.about-address__item-inner {
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	display: block;

	&:hover {

		.about-slider__img {
			transform: scale(1.15); }

		.about-slider__info {
			transform: translateY(calc(0%)); }

		.about-slider__more {
			opacity: 1; }

		.about-address__img {
			transform: scale(1.15); } }

	@include mobile {
		aspect-ratio: 3/4; }

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, rgba(52, 54, 51, 0) 0%, #000 80.09%);
		opacity: .85; } }


.about-slider__info {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	padding-bottom: 29px;
	transform: translateY(calc(100% - 24px));
	transition-duration: var(--trds);
	text-align: center;

	@include mobile {
		transform: translateY(calc(0%)); } }

.about-slider__title {
	font-weight: 700;
	font-size: 16px;
	color: var(--white);
	margin-bottom: 10px;
	position: absolute;
	line-height: normal;
	bottom: 100%;
	width: 100%; }

.about-slider__more {
	color: var(--gray);
	text-decoration: none;
	transition-duration: var(--trds);
	opacity: 0;

	@include mobile {
		opacity: 1; } }

.about-address__img {
	object-fit: cover;
	object-position: 50% 50%;
	height: 100%;
	width: 100%;
	transition-duration: var(--trds); }

.about-address__title {
	font-weight: 700;
	font-size: 16px;
	color: var(--white);
	position: absolute;
	line-height: normal;
	width: 100%;
	bottom: 33px;
	left: 0;
	text-align: center;
	z-index: 2;

	@include mobile {
		bottom: 19px; } }

.section__youtube {
	padding-top: 62px;
	padding-bottom: 58px;
	background: #F3F0F3;

	@include mobile {
		padding-top: 48px;
		padding-bottom: 48px; }

	.section__title {
		margin-bottom: 24px;

		@include tablet {
			font-size: 30px;
			line-height: 100%; } } }

.youtube__slider-wrap {
	margin-left: 0px;
	margin-right: 0px;
	padding-left: 30px;
	padding-right: 30px;
	position: relative;

	@include mobile {
		padding: 0;
		margin-left: -16px;
		margin-right: -20px; }

	.slick-list {
		height: 100%; }

	.slick-track {
		display: flex;
		align-items: center;
		height: 100%; }

	.container-fluid {
		height: 100%;
		position: relative; }

	.slick-prev {
		display: none; }

	.sert-slider__arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
		pointer-events: none;

		@include mobile {
			display: none; } }

	.slider-arrow__prev {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		left: 0px;
		top: 50%;
		margin-top: -18px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			background: #8B658A;

			svg {
				fill: var(--white); } }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds);
			transform: rotate(180deg); } }

	.slider-arrow__next {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		right: 0px;
		top: 50%;
		margin-top: -18px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			background: #8B658A;

			svg {
				fill: var(--white); } }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds); } } }

.youtube__slider {
	margin-left: -1.5%;
	margin-right: -1.5%;
	height: 100%;
	aspect-ratio: 4.75/1;

	@include mobile {
		aspect-ratio: auto;
		margin: 0; } }

.youtube-slider__img {
	border-radius: 20px; }

.youtube-slider__item {
	position: relative;
	padding-left: 13px;
	padding-right: 13px;
	transition-duration: var(--trds);
	display: flex !important;
	align-items: center;

	@include mobile {
		width: 326px; }

	&.slick-center {

		.youtube-slider__item-inner {
			width: 100%;
			padding: 0;
			transform: scale(1.19); } } }

.youtube-slider__item-inner {
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	transition-duration: var(--trds);
	transition-delay: .25s;
	width: 85%;
	margin: 0 auto;

	@include mobile {
		width: 100%; } }

.youtube__more {
	max-width: 428px;
	margin: 0 auto;
	margin-top: 48px;

	@include mobile {
		margin-top: 22px; } }

.section__reviews {
	background: url(../images/content/main/reviews_bg.svg) no-repeat 50% 50% var(--main);
	background-size: cover;
	padding-top: 62px;
	padding-bottom: 58px;

	@include mobile {
		padding-top: 50px;
		padding-bottom: 47px; }

	.section__title {
		margin-bottom: 25px;
		color: var(--white);

		@include tablet {
			font-size: 30px;
			line-height: 100%; }

		@include mobile {
			margin-bottom: 14px; } } }

.reviews__slider-wrap {
	position: relative;
	padding-left: 80px;
	padding-right: 80px;

	@include mobile {
		padding: 0;
		margin-left: -13px;
		margin-right: -20px; }

	.slick-track {
		display: flex !important; }

	.container-fluid {
		height: 100%;
		position: relative; }

	.slick-prev {
		display: none; }

	.sert-slider__arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
		pointer-events: none;

		@include mobile {
			display: none; } }

	.slider-arrow__prev {
		width: 36px;
		height: 36px;
		border: 1px solid var(--white);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		left: 30px;
		top: 50%;
		margin-top: -18px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			background: #6d3d53; }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--white);
			transition-duration: var(--trds);
			transform: rotate(180deg); } }

	.slider-arrow__next {
		width: 36px;
		height: 36px;
		border: 1px solid var(--white);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		right: 30px;
		top: 50%;
		margin-top: -18px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			background: #6d3d53; }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--white);
			transition-duration: var(--trds); } } }

.reviews-slider__item {
	position: relative;
	padding-left: 13px;
	padding-right: 13px;
	height: auto !important;
	float: none;

	@include mobile {
		width: 326px; } }

.reviews-slider__item-inner {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	background: var(--white);
	height: 100%;
	display: flex;
	justify-content: center;
	padding: 15px;
	align-items: flex-start;

	img {
		max-width: 100%;
		width: auto !important; } }

.reviews__more {
	max-width: 428px;
	margin: 0 auto;
	margin-top: 48px;

	@include mobile {
		margin-top: 25px; } }

.lips {
	padding-top: 25px;
	padding-bottom: 25px;

	@include tablet {
		padding-top: 0; }

	ol {
		margin-bottom: 15px; }

	p {
		margin-bottom: 10px; } }






