.bar {
	background: var(--main);
	color: var(--white);
	line-height: normal;
	padding-top: 4px;
	padding-bottom: 4px;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	position: relative; }

.bar__scroll {
	position: relative;
	left: 0%; }

.bar__list {
	display: flex;
	position: relative;
	width: fit-content; }

.bar-list__double {
	left: 100%;
	position: absolute;
	display: flex;
	top: 0; }

.bar-scroll__item {
	display: flex;
	align-items: center;
	padding-right: 16px;
	padding-left: 16px; }

.bar-scroll__img {
	width: 27px;
	height: 35px;
	margin: 1.5px 5px;
	margin-right: 10px; }

header {

	.container-fluid {
		position: relative; }

	&.header__main {
		background: url(../images/content/main/header.jpg) no-repeat 50% 50%;
		background-size: cover;
		height: 643px;
		position: relative;
		color: var(--white);
		_overflow: hidden;

		@include mobile {
			height: auto; }

		.container-fluid {
			display: flex;
			flex-direction: column;
			_height: 100%; }

		&::before {
			_content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(0,0,0,.85);

			@include mobile {
				background: rgba(0,0,0,.7); } }

		.icon-logo {
			fill: var(--white);
			width: 66px;
			height: 88px;

			@include tablet {
				display: none; } }

		.icon-logo_mobile {
			fill: var(--white);
			width: 113px;
			height: 55px;
			display: none;

			@include tablet {
				display: block; } } }

	&.header__service {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		height: 548px;
		position: relative;
		color: var(--white);
		_overflow: hidden;

		@include tablet {
			background-position: 50% 76px; }


		&::after {

			@include tablet {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				height: 76px;
				background: #fff; } }

		@include mobile {
			height: auto;
			height: 500px; }

		.container-fluid {
			display: flex;
			flex-direction: column;
			_height: 100%;
			z-index: 2; }

		.header__main-wrap > .container-fluid {
			z-index: 3; }

		.header__content-wrap {
			justify-content: center;
			display: flex;
			align-items: center;

			.container-fluid {
				height: auto; } }

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(0,0,0,.85);
			background: linear-gradient(270deg, rgba(52, 54, 51, 0) 19.68%, #343633 100%);
			background-blend-mode: multiply;


			@include mobile {
				_background: rgba(0,0,0,.7); } }

		.icon-logo {
			fill: var(--white);
			width: 66px;
			height: 88px;

			@include tablet {
				display: none; } }

		.icon-logo_inner_mobile {
			fill: var(--white);
			width: 113px;
			height: 55px;
			display: none;

			@include tablet {
				display: block;
				fill: var(--main); } }

		a.open__nav {

			@include tablet {

				span {
					background: var(--text); } } }

		.head_banner_container, .intro_int {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			_margin-top: 46px; }

		.top_price {
			display: flex;
			align-items: flex-end;
			flex-wrap: wrap;

			span {
				font-size: 40px;
				font-weight: 500;
				line-height: 50px;

				@include mobile {
					font-size: 30px;
					line-height: 30px; } }

			span.line {
				position: relative;
				font-size: 24px;
				font-weight: 300;
				order: 2;
				line-height: 35px;
				margin-left: 5px;

				@include mobile {
					font-size: 20px;
					line-height: 22px; }

				&::before {
					content: '';
					height: 1px;
					position: absolute;
					background: #fff;
					left: 0;
					right: 0;
					top: 50%; } } }

		.head_banner_text {
			margin-top: 16px; }

		.open__popup, .head_banner_button, .intro_btn {
			display: flex;
			align-items: center;
			padding: 18px 24px;
			font-weight: 700;
			font-size: 16px;
			color: var(--white);
			justify-content: space-between;
			line-height: normal;
			text-decoration: none;
			backdrop-filter: blur(10px);
			background: rgba(255, 255, 255, 0.1);
			border: 1px solid #fff;
			border-radius: 10px;
			transition-duration: var(--trds);
			width: 100%;
			max-width: 396px;
			text-align: center;
			justify-content: center;
			margin-top: 38px;
			cursor: pointer;

			&:first-letter {
				text-transform: uppercase; }

			@include tablet {
				font-size: 16px;
				padding-top: 11px;
				padding-bottom: 11px; }

			&:hover {
				background: rgba(255, 255, 255, 0.3);

				.service-link__icon {
					background: var(--main);

					svg {
						fill: var(--white); } } }

			&:active {
				background: rgba(255, 255, 255, 0.5);

				.service-link__icon {
					background: var(--main);

					svg {
						fill: var(--white); } } } }

		.head-anchor {
			display: flex;
			flex-direction: column;
			gap: 5px;
			margin-top: 10px;

			br {
				display: none; }

			a {
				color: #fff; } } }

	&.header__inner {

		.icon-logo_inner {
			width: 66px;
			height: 88px;

			@include tablet {
				display: none; } }

		.icon-logo_inner_mobile {
			width: 113px;
			height: 55px;
			display: none;

			@include tablet {
				display: block; } }

		.nav__link {
			color: var(--text);

			&:hover {
				color: var(--main); } }

		a.open__nav {

			span {
				background: var(--text); } } } }


.header-logo__link {
	display: block;
	padding: 16px 8px;

	@include tablet {
		padding: 10px 20px;
		margin-left: -20px; } }

.header__nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;

	@include tablet {
		padding-top: 25px; } }

.page--inner {

	.header__nav {

		@include tablet {
			padding-top: 0px; } } }

.nav {
	display: flex;
	align-items: center;

	@include tablet {
		display: none; } }

.nav__list {
	display: flex;
	align-items: center;
	gap: 24px; }

.nav__link {
	color: var(--white);
	text-decoration: none;
	position: relative;
	white-space: nowrap;

	&:hover {
		color: var(--main);

		&::after {
			opacity: 1; } }

	&::after {
		content: '';
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 100%;
		background: var(--main);
		height: 1px;
		transition-duration: var(--trds);
		width: 100%;
		opacity: 0; } }

.parent--dropdown {

	&:hover {

		.dropdown__service-wrap {
			opacity: 1;
			margin-top: 0;
			visibility: visible; }

		.dropdown__item {

			&.active > .dropdown__link {
				background: #fff; }


			&.active > .dropdown-childs__list {
				opacity: 1;
				visibility: visible;
				margin-left: 0; } } } }

.dropdown__item {


	&:nth-child(1),&:nth-child(3) {

		& > .dropdown-childs__list {
			z-index: 1; }

		&::after {
			_content: '';
			width: 16px;
			height: 8px;
			display: block;
			background: url('data:image/svg+xml,<svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.3894 4.17844C15.583 3.9816 15.5805 3.66503 15.3836 3.47135L12.176 0.315237C11.9792 0.121561 11.6626 0.124125 11.4689 0.320961C11.2752 0.517798 11.2778 0.83437 11.4746 1.02804L14.3259 3.83348L11.5204 6.68471C11.3268 6.88155 11.3293 7.19812 11.5262 7.3918C11.723 7.58547 12.0396 7.58291 12.2332 7.38607L15.3894 4.17844ZM0.00420268 4.44945L15.037 4.32774L15.0289 3.32777L-0.00389311 3.44948L0.00420268 4.44945Z" fill="%23858585"/></svg>') no-repeat 50% 50%;
			margin: 0 8px;
			margin-bottom: 2px;
			margin: 0;
			position: absolute;
			top: 28px;
			right: 6px;
			transform: rotate(90deg);
			z-index: 2; } }

	& > .dropdown-childs__list {
		z-index: 3;
		position: absolute;
		left: 374px;
		right: 24px;
		right: 10px;
		_margin-right: 14px;
		background: #fff;
		border-right: 14px solid #fff;
		top: 24px;
		opacity: 0;
		visibility: hidden;
		transition-duration: 0.05s;
		max-height: 500px;
		overflow: auto;

		//@include scrollbars(8px, var(--main), #fff)

		& > .dropdown-childs__item {
			overflow: hidden;

			& + .dropdown-childs__item {
				margin-top: 6px;
				border-top: 1px solid #c4c4c4;
				padding-top: 8px; }

			& > .dropdown-childs__link {
				font-weight: 700;
				font-size: 20px;
				color: rgba(52, 54, 51, 0.8);
				text-decoration: none;
				display: block;
				margin-bottom: 5px;
				padding: 8px 16px;
				float: left;
				border-radius: 30px;

				@include width1200 {
					font-size: 18px; }

				&:hover {
					background: rgba(139, 101, 138, 0.1); } } } } }

.dropdown__link {
	padding: 8px 48px 8px 24px;
	display: block;
	font-weight: 700;
	font-size: 20px;
	color: var(--text);
	text-decoration: none;
	position: relative;

	@include width1200 {
		font-size: 18px; }

	&::after {
		content: '';
		width: 7px;
		height: 12px;
		background: url('data:image/svg+xml,<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.83983 5.62617L1.08468 0.142609C0.988647 0.051067 0.861068 0 0.728398 0C0.595729 0 0.46815 0.051067 0.372123 0.142609L0.365926 0.148805C0.319212 0.193183 0.282016 0.2466 0.256598 0.305807C0.231179 0.365014 0.218071 0.428773 0.218071 0.493206C0.218071 0.557638 0.231179 0.621397 0.256598 0.680604C0.282016 0.739811 0.319212 0.793228 0.365926 0.837606L5.78546 6.00103L0.365926 11.1624C0.319212 11.2068 0.282016 11.2602 0.256598 11.3194C0.231179 11.3786 0.218071 11.4424 0.218071 11.5068C0.218071 11.5712 0.231179 11.635 0.256598 11.6942C0.282016 11.7534 0.319212 11.8068 0.365926 11.8512L0.372123 11.8574C0.46815 11.9489 0.595729 12 0.728398 12C0.861068 12 0.988647 11.9489 1.08468 11.8574L6.83983 6.37383C6.89044 6.32561 6.93074 6.26761 6.95827 6.20335C6.9858 6.13909 7 6.06991 7 6C7 5.93009 6.9858 5.86091 6.95827 5.79665C6.93074 5.73239 6.89044 5.67439 6.83983 5.62617Z" fill="%238B658A"/></svg>') no-repeat;
		display: block;
		position: absolute;
		top: 12px;
		right: 24px; } }

.dropdown-childs__list {

	.dropdown-childs__list {
		column-count: 3;
		column-gap: 0px;
		clear: both;

		@include width1200 {
			column-count: 2; }

		.dropdown-childs__item.active, .dropdown-childs__item:hover {

			.dropdown-childs__link {
				background: rgba(139, 101, 138, 0.1); } }

		.dropdown-childs__link {
			font-weight: 400;
			font-size: 16px;
			line-height: 130%;
			color: rgba(52, 54, 51, 0.8);
			display: block;
			padding: 3px 14px 3px 30px;
			text-decoration: none;
			border-radius: 30px;
			margin-bottom: 1px;
			position: relative;
			display: inline-block;

			@include width1200 {
				font-size: 14px; }

			&::after {
				content: '';
				position: absolute;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: rgba(52, 54, 51, 0.5);
				left: 15px;
				top: 11px; } } } }

.dropdown__service-wrap {
	position: absolute;
	top: calc(100% - 55px);
	z-index: 5;
	left: 0;
	right: 0;
	padding-top: 45px;
	opacity: 0;
	visibility: hidden;
	margin-top: 50px;
	transition-duration: var(--trds); }

.dropdown__service {
	background: #FFFFFF;
	box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.15);
	border-radius: 20px;
	display: flex;
	justify-content: space-between;
	position: relative; }

.dropdown-service__cats {
	width: 350px;
	min-width: 350px;
	background: rgba(139,101,138,0.1);
	padding-top: 24px;
	padding-bottom: 24px;
	border-radius: 20px 0 0 20px; }

.dropdown-service__items {
	flex: 1 0 auto;
	padding: 24px; }

.search__open {
	width: 57px;
	height: 57px;
	border-radius: 50%;
	background: var(--main);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 40px;
	transition-duration: var(--trds);
	position: absolute;
	right: 15px;

	&:hover {
		background: var(--main-hover);

		svg {
			_stroke: var(--main); } }

	svg {
		stroke: var(--white);
		width: 25px;
		height: 25px;
		stroke-width: 2px;
		transition-duration: var(--trds); } }

.search__block {
	width: 0;
	overflow: hidden;
	margin-left: 97px;
	transition-duration: var(--trds);
	border-radius: 60px;
	overflow: hidden;
	opacity: 0;

	&.active {
		width: 450px;
		margin-left: 40px;
		opacity: 1;

		@include laptop {
			width: 300px; } } }

.search-block__form {
	display: flex;
	border-radius: 60px;
	padding: 11px 35px 11px 35px;
	background: var(--white);
	background: #f3f0f3;
	position: relative;
	z-index: 2; }

.search-block__button {
	width: 36px;
	height: 36px;
	background: transparent;
	border: none;
	cursor: pointer;

	svg {
		stroke: var(--main);
		width: 25px;
		height: 25px;
		stroke-width: 2px; } }

.seach-form__input {
	padding: 0;
	margin: 3px 0 0 8px;
	border: none;
	background: #f3f0f3; }

.header__content-wrap {
	overflow: hidden;
	flex: 1 0;

	.container-fluid {
		height: 100%; } }

.header__main-wrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%; }

.header__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	padding-top: 50px;
	padding-top: 20px;
	padding-bottom: 20px;
	flex: 1 0;

	@include mobile {
		padding-top: 10px;
		padding-bottom: 26px; } }

.header__title {
	line-height: 100%;
	padding: 0 20%;
	margin-bottom: 0;

	@include laptop {
		padding: 0 5%; }

	@include tablet {
		font-size: 35px;
		margin-bottom: 22px; } }

.header__count {
	display: flex;
	justify-content: center;
	gap: 24px;
	margin-top: 70px;

	@include mobile {
		flex-direction: column;
		align-items: center;
		gap: 28px;
		margin-top: 55px;
		margin-bottom: 25px; } }

.header-count__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	@include mobile {
		width: 200px; } }

.header-count__num {
	font-size: 40px;
	margin-bottom: 23px;

	@include tablet {
		font-size: 30px; }

	@include mobile {
		margin-bottom: 13px; } }

.header-count__name {

	span {
		display: block; }

	@include tablet {
		font-size: 16px; }

	@include mobile {
		line-height: normal;

		span {
			display: inline-block;
			width: 5px; } } }

.header__button {
	margin-top: 63px;
	width: 100%;
	max-width: 428px;

	@include mobile {
		margin-top: 27px; } }

.header__sale {
	position: absolute;
	top: 50%;
	top: 292px;
	top: 195px;
	right: 68px;
	margin-top: -15px;

	@include tablet {
		display: none; }

	&:hover {

		.header-sale__arrow {
			background: var(--main); }

		svg {
			fill: var(--white); } } }

.header-sale__arrow {
	background: var(--white);
	width: 90px;
	height: 90px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition-duration: var(--trds);

	svg {
		width: 39px;
		height: 15px;
		fill: var(--text);
		transition-duration: var(--trds); } }

.header-sale__text {
	position: absolute;
	top: 0;
	left: 0;

	svg {
		width: 220px;
		height: 220px;
		fill: var(--white);
		top: 50%;
		left: 50%;
		margin: -65px 0 0 -65px;
		animation: 20s linear 0s infinite rotate; } }

@keyframes rotate {
	from {
		transform: rotate(0); }
	to {
		transform: rotate(360deg); } }

a.open__nav {
	background: var(--blue);
	display: flex;
	align-items: center;
	width: 34px;
	height: 34px;
	min-width: 34px;
	color: var(--white);
	text-decoration: none;
	white-space: nowrap;
	transition: .5s ease-in-out;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 14px;
	display: none;
	position: relative;
	margin-right: 15px;

	_position: absolute;
	_top: 15px;
	_right: 10px;

	@include tablet {
		display: flex; }

	svg {
		width: 32px;
		height: 32px;

		&.icon-close {
			display: none; } }

	@include mobile {
		display: flex; }

	&.open__nav {}

	span {
		display: block;
		height: 2px;
		width: 100%;
		background: var(--white);
		border-radius: 3px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out; } }


