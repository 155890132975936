.section__content-wrap {
	padding-top: 63px;
	padding-bottom: 63px;

	@include tablet {
		padding-bottom: 24px; } }

.section__breacrumbs {
	display: flex;
	margin-bottom: 39px;
	flex-wrap: wrap;

	@include tablet {
		margin-bottom: 18px; } }

.breabcrumbs__link {
	color: #888;
	text-decoration: none; }

.breadcrumbs__item {
	display: flex;
	align-items: center;
	margin-bottom: 8px;

	&:last-child {
		white-space: nowrap;

		&::after {
			display: none; } }

	&::after {
		content: '';
		width: 16px;
		height: 8px;
		display: block;
		background: url('data:image/svg+xml,<svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.3894 4.17844C15.583 3.9816 15.5805 3.66503 15.3836 3.47135L12.176 0.315237C11.9792 0.121561 11.6626 0.124125 11.4689 0.320961C11.2752 0.517798 11.2778 0.83437 11.4746 1.02804L14.3259 3.83348L11.5204 6.68471C11.3268 6.88155 11.3293 7.19812 11.5262 7.3918C11.723 7.58547 12.0396 7.58291 12.2332 7.38607L15.3894 4.17844ZM0.00420268 4.44945L15.037 4.32774L15.0289 3.32777L-0.00389311 3.44948L0.00420268 4.44945Z" fill="%23858585"/></svg>') no-repeat 50% 50%;
		margin: 0 8px;
		margin-bottom: 2px; } }

.section__content {}

.page__title {
	color: var(--main);
	text-align: center;
	margin-bottom: 15px; }

.content__desc {
	width: 100%;
	max-width: 780px;
	margin: 0 auto 48px;
	text-align: center;
	color: #888888; }

.docs__item {
	display: flex;
	flex-direction: column;
	text-decoration: none;
	transition-duration: var(--trds);
	border-radius: 10px;
	overflow: hidden;

	&:hover {
		background: #F3F0F3;

		.docs__link {
			background: #6d3d53;

			svg {
				fill: var(--white); } } } }

.docs__img {
	display: block;
	width: 100%; }

.docs__list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 62px 26px;

	@include laptop {
		grid-template-columns: repeat(3, 1fr); }

	@include tablet {
		grid-template-columns: repeat(2, 1fr); }

	@include mobile {
		grid-template-columns: repeat(1, 1fr); } }

.docs__info {
	padding: 16px;
	margin-top: 21px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex: 1 0; }

.docs__title {
	color: #555555;
	font-weight: 600;
	font-size: 16px;
	line-height: normal; }

.docs__intro {
	color: var(--main);

	@include mobile {
		margin-bottom: 6px; } }

.docs__bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: auto;
	gap: 16px; }

.docs-bottom__list {
	flex: 1 0;
	font-size: 14px;
	color: #888; }

.doc-bottom__name {
	font-weight: 600;
	width: 95px;
	min-width: 95px; }

.doc-bottom__item {
	display: flex;
	_justify-content: space-between;
	gap: 0px 0px;
	_flex-wrap: wrap;

	& + .doc-bottom__item {
		margin-top: 0px;

		@include tablet {
			margin-top: 6px; } } }

.docs__link {
	width: 36px;
	height: 36px;
	display: flex;
	border: 1px solid var(--main);
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	min-width: 36px;
	margin-left: 8px;
	transition-duration: var(--trds);

	&:hover {
		background: #6d3d53;

		svg {
			fill: var(--white); } }

	svg {
		fill: var(--main);
		width: 15px;
		height: 12px;
		transition-duration: var(--trds); } }

.docs__more {
	max-width: 428px;
	margin: 0 auto;
	margin-top: 48px; }

.doc__inner {
	display: flex;
	gap: 27px;
	margin-bottom: 10px;

	@include mobile {
		flex-direction: column; } }

.doc-inner__image {
	width: 41.5%;

	@include mobile {
		width: 100%; } }

.doc-inner__img {
	display: block;
	width: 100%;
	border-radius: 15px; }

.doc-inner__info {
	width: 58.5%;

	@include mobile {
		width: 100%; } }

.doc-inner__title {
	font-family: var(--font-main);
	font-size: 16px;
	line-height: normal;
	font-weight: 700;
	color: var(--main);
	margin-bottom: 26px; }

.doc-inner__content {
	color: #888;

	p + p {
		margin-top: 9px; } }

.doc-inner__spec {
	background: #F3F0F3;
	border-radius: 10px;
	padding: 24px;
	margin-top: 10px;
	color: #888;

	ul {
		_columns: 2 auto;
		margin-top: 18px;
		display: grid;
		grid-template-columns: auto auto;
		justify-content: start;
		gap: 0 16px;

		@include mobile {
			grid-template-columns: auto; }

		li {
			position: relative;
			padding-left: 25px;

			@include mobile {

				& + li {
					margin-top: 10px; } }

			&::after {
				content: '';
				width: 3px;
				height: 3px;
				border-radius: 50%;
				position: absolute;
				background: #888;
				left: 10px;
				top: 10px; } } } }

.inner-spec__title {
	font-size: 16px;
	line-height: normal;
	font-weight: 700;
	color: var(--main);
	margin-bottom: 10px; }

.map.full {
    width: 100%;
    height: 500px;
    border: none; }

.faq__section {
	display: flex;
	justify-content: space-between;

	@include tablet {
		flex-direction: column; } }

.page__faq {
	color: var(--main); }

.faq__title {
	padding-right: 45px;
	width: 430px;

	@include tablet {
		width: 100%; } }

.faq__quest {
	position: relative;
	font-size: 16px;
	font-weight: 700;
	line-height: normal;
	cursor: pointer;
	text-transform: uppercase;
	padding-right: 35px;

	&.open {

		&::after {
			content: '';
			width: 23px;
			height: 23px;
			border-radius: 50%;
			background: var(--main);
			position: absolute;
			right: 4px;
			top: 3px; }

		svg {
			transform: rotate(180deg);
			top: -1px;
			stroke: var(--white);
			fill: var(--main); } }

	svg {
		width: 32px;
		height: 32px;
		stroke: var(--main);
		stroke-width: 2px;
		position: absolute;
		top: -2px;
		right: 0;
		z-index: 2; } }

.faq__list {
	display: flex;
	flex-direction: column;
	gap: 16px;
	flex: 1 0; }

.faq__item {
	background: #F3F0F3;
	padding: 24px;
	border-radius: 10px; }

.faq__content {
	display: none;
	padding-top: 10px; }

.section__content-contacts {
	padding-bottom: 0; }

.contacts__slider-wrap {
	margin-left: -13px;
	margin-right: -13px;
	margin-bottom: 62px;

	.slick-track {
		display: flex;

		.slick-slide {
			height: auto; } }

	@include mobile {
		margin-left: -20px;
		margin-right: -20px; } }

.contacts__item {
	padding-left: 16px;
	padding-right: 16px;

	@include tablet {
		width: 335px;
		padding-left: 8px;
		padding-right: 8px; }

	@include mobile {
		width: 260px; } }

.contacts__item-inner {
	padding: 48px 60px;
	background: #F3F0F3;
	border-radius: 10px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include laptop {
		padding: 30px 20px; } }

.contacts-item__img {
	display: block;
	width: 100%;
	margin-bottom: 16px; }

.contacts-item__info {
	padding-left: 16px;
	padding-right: 16px;
	font-size: 14px;
	color: #888;
	flex: 1 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between; }

.contacts-item__name {
	font-size: 16px;
	font-weight: 700;
	color: #555555;
	margin-bottom: 24px;

	span {
		display: block;
		color: var(--main);
		font-weight: 400;
		margin-bottom: 8px;
		font-size: 16px; } }

.contact-item__button {
	height: 49px;
	margin-top: 24px;
	margin-top: auto; }

.contacts-item__adres {
	line-height: 130%;
	margin-bottom: 8px;

	span {
		display: block;
		color: var(--main);
		font-weight: 400;
		margin-bottom: 8px;
		font-size: 16px; } }

.contacts-item__time {
	line-height: 130%;
	margin-bottom: 8px;

	span {
		display: block;
		color: var(--main);
		font-weight: 400;
		margin-bottom: 8px;
		font-size: 16px; } }

.contacts-item__phones {
	line-height: 130%;
	margin-bottom: 8px;

	span {
		display: block;
		color: var(--main);
		font-weight: 400;
		margin-bottom: 8px;
		font-size: 16px; } }

.contacts-item__wa {
	display: flex;
	margin-bottom: 8px;

	a {
		color: #888;
		text-decoration: none; }

	svg {
		width: 20px;
		height: 20px;
		min-width: 20px;
		margin-right: 8px;
		fill: var(--main); } }

.contacts-item__mail {
	display: flex;
	margin-bottom: 24px;

	a {
		color: #888; }

	svg {
		width: 20px;
		height: 20px;
		min-width: 20;
		margin-right: 8px;
		fill: var(--main); } }

.blog__list {
	display: grid;
	gap: 46px;
	grid-template-columns: repeat(4, 1fr);
	margin-bottom: 48px;

	@include laptop {
		grid-template-columns: repeat(3, 1fr); }

	@include tablet {
		grid-template-columns: repeat(2, 1fr); }

	@include mobile {
		grid-template-columns: repeat(1, 1fr);
		gap: 24px;
		padding-left: 24px;
		padding-right: 24px; } }

.page-658 {

	.page__title {
		max-width: 600px;
		width: 100%;
		margin-left: auto;
		margin-right: auto; }

	.pagination {
		display: none; } }


.blog--problem {
	grid-template-columns: repeat(3, 1fr);

	@include tablet {
		grid-template-columns: repeat(2, 1fr); }

	@include mobile {
		grid-template-columns: repeat(1, 1fr); }

	.blog-item__link {
			font-weight: 700;
			font-size: 20px;
			text-transform: uppercase;
			color: var(--main);
			line-height: normal;
			margin-bottom: 8px; }

	.blog-item__intro {
		margin-bottom: 8px;
		font-size: 18px; }

	.blog__item {
		display: flex;
		flex-direction: column;
		justify-content: space-between; }

	.blog-item__info {
		flex: 1 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between; } }


.blog-item__arrow {
	margin-top: auto;
	font-weight: 700;
	font-size: 16px;
	line-height: 130%;
	text-decoration: underline;
	color: #888;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:hover {
		span {
			background: #8B658A;

			svg {
				fill: var(--white); } } }

	span {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		background: transparent;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		@include width1400 {
			_right: 25px; }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds); } } }

.blog__item {
	border-radius: 10px;
	transition-duration: var(--trds);

	&:hover {
		background: #F3F0F3; } }

.blog-item__img {
	display: block;
	width: 100%;
	margin-bottom: 8px;
	border-radius: 10px 10px 0 0; }

.blog-item__info {
	padding: 16px; }

.blog-item__values {
	display: flex;
	gap: 16px;
	justify-content: space-between;
	font-size: 12px;
	color: #888;
	line-height: 130%;
	margin-bottom: 8px;
	align-items: center; }

.blog-item__value {
	display: flex;
	align-items: center;

	&:first-child {
		margin-right: auto; }

	svg {
		width: 20px;
		height: 20px;
		min-width: 20px;
		margin-left: 8px;

		&.icon-icon_time {
			stroke: #858585;
			stroke-width: 1.5px; }

		&.icon-icon_view {
			fill: #858585; } } }

.blog-item__link {
	font-size: 16px;
	font-weight: 700;
	color: #555;
	text-decoration: none;

	&:hover {
		color: var(--main); } }

.blog__more {
	max-width: 428px;
	margin: 0 auto;
	margin-top: 48px; }

.btn-more {
	width: 100% !important;
	padding-top: 19px;
	padding-bottom: 19px; }

.services-cat__list {
	display: grid;
	gap: 46px;
	grid-template-columns: repeat(4, 1fr);
	margin-top: 50px;

	@include mobile;

	@include laptop {
		grid-template-columns: repeat(3, 1fr); }

	@include tablet {
		grid-template-columns: repeat(2, 1fr); }

	@include mobile {
		grid-template-columns: repeat(1, 1fr);
		gap: 24px;
		padding-left: 24px;
		padding-right: 24px; } }

.services-cat__list--subservice {
	grid-template-columns: repeat(3, 1fr);
	gap: 26px;

	@include tablet {
		grid-template-columns: repeat(2, 1fr); }

	@include mobile {
		grid-template-columns: repeat(1, 1fr);
		padding-left: 0;
		padding-right: 0; } }

.services-cat__item {
	position: relative;
	border-radius: 20px;
	overflow: hidden;

	&:hover {

		.service-cat__img {
			transform: scale(1.15); } }

	&:hover {

		.service-cat__arrow {
			background: var(--main);

			svg {
				fill: var(--white); } } }

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, rgba(52, 54, 51, 0) 0%, rgba(0, 0, 0, .85) 100%);
		opacity: .85; } }

.service-cat__img {
	display: block;
	width: 100%;
	transition-duration: var(--trds); }

.service-cat__arrow {
	position: absolute;
	background: #fff;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	position: absolute;
	top: 26px;
	right: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition-duration: var(--trds);
	z-index: 2;

	svg {
		width: 14px;
		height: 10px;
		fill: var(--main);
		transition-duration: var(--trds);
		transform: rotate(-45deg);

		@include mobile {
			_fill: var(--white); } } }

.service-cat__title {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 22px 14px;
	min-height: 95px;
	text-align: center;
	color: #fff;
	text-decoration: none;
	z-index: 2;
	font-size: 16px;
	font-weight: 700;
	line-height: normal; }

.section__servicei {
	background: #F3F0F3;
	padding-top: 62px;
	padding-bottom: 62px; }

.servicei__wrap {
	display: flex;
	gap: 24px;

	@include tablet {
		flex-direction: column; } }

.servicei__info {
	width: 33%;

	@include tablet {
		width: 100%; } }

.servicei__title {
	color: var(--main); }

.servicei__list {
	width: 67%;
	display: flex;
	flex-direction: column;
	gap: 16px;

	@include tablet {
		width: 100%; } }

.servicei__item {
	background: var(--white);
	border-radius: 10px;
	padding: 24px; }

.servicei-item__title {
	font-size: 16px;
	line-height: normal;
	font-weight: 700;
	position: relative;
	cursor: pointer;

	&.open {

		&::after {
			content: '';
			width: 23px;
			height: 23px;
			border-radius: 50%;
			_background: var(--main);
			position: absolute;
			right: 4px;
			top: 3px; }

		svg {
			transform: rotate(180deg);
			top: -1px;
			_stroke: var(--white);
			_fill: var(--main); } }

	svg {
		width: 32px;
		height: 32px;
		stroke: var(--main);
		stroke-width: 2px;
		position: absolute;
		top: -2px;
		right: 0;
		z-index: 2; } }

.servicei-item__list-wrap {
	display: none; }

.servicei-item__list {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	gap: 10px; }

.servicei-item__link {
	padding-left: 25px;
	position: relative;
	text-decoration: none;

	&:hover {
		color: var(--main); }

	&::after {
		content: '';
		position: absolute;
		width: 4px;
		height: 4px;
		background: var(--black);
		border-radius: 50%;
		top: 7px;
		left: 6px;

		@include mobile {
			top: 5px;
			left: 5px; } } }

.price__wrap {
	display: flex;
	gap: 32px;

	@include tablet {
		flex-direction: column; } }

.price__info {
	width: 33%;

	@include tablet {
		width: 100%; } }

.price__title {
	color: var(--main); }

.price__list {
	width: 67%;
	display: flex;
	flex-direction: column;
	gap: 16px;

	@include tablet {
		width: 100%; }

	.wrap_cena {
		display: flex;
		flex-direction: column;
		gap: 16px; }

	.cena__wrap {
		background: #F3F0F3;
		border-radius: 10px;
		padding: 24px;

		.container {
			padding: 0;
			max-width: 100%; } }

	.cena_title {
		font-size: 16px;
		font-weight: 700;
		position: relative;
		cursor: pointer;
		padding-right: 45px;

		&.open {

			&::after {
				transform: rotate(0); } }

		&::after {
			content: '';
			width: 32px;
			height: 32px;
			position: absolute;
			top: -3px;
			right: 0px;
			transform: rotate(180deg);
			background: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 4C9.37259 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4Z" stroke="%238B658A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M21 19L16 14L11 19" stroke="%238B658A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat 50% 50%; } }

	.cena_content {
		margin-top: 10px;

		.line_other_two + .container {
			margin-top: 24px; }

		.price_top {
			margin-bottom: 10px;
			font-weight: 700; } }

	.price_content {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 8px;

		.price_box_one {
			flex: 1 0; }

		.price_box_two {
			text-align: center;
			_white-space: nowrap;
			min-width: 120px;

			&:nth-child(2) {
				min-width: 120px;
				display: none;

				.price_title_box {
					white-space: nowrap;
					position: relative;

					&::after {
						content: 'без скидки';
						margin-left: 0px;
						position: absolute;
						color: var(--text);
						left: 50%;
						bottom: -15px;
						transform: translateX(-50%);
						font-size: 14px;
						font-weight: 400; } } } }

		.price_title_box {
			color: var(--main);
			font-weight: 700;
			margin-bottom: 10px; }

		.price_free {
			display: none; }

		.price_text, .pr, .price_ray {
			padding-top: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid var(--white); }

		.price_ray {
			padding-left: 24px;
			padding-right: 24px; } } }

.section__content-about {
	padding-bottom: 0; }

.section__specs {
	padding-top: 64px;
	padding-bottom: 64px;

	.button {
		max-width: 428px;
		margin: 0 auto;
		margin-top: 48px; }

	.services__list {
		max-width: 485px;

		.service__link {

			&:hover {
				background: rgba(255, 255, 255, 0.1); } } }

	.section__services {
		padding-top: 62px;
		padding-bottom: 62px;
		background: url(../images/content/about/return.jpg) no-repeat 50% 50% / cover;

		@include mobile {
			background: url(../images/content/about/return_mob.jpg) no-repeat 50% 50% / cover; } } }


.about__header {
	padding-top: 25px;
	padding-bottom: 70px;
	background: url(../images/content/about/about_bg.svg) no-repeat 50% 50% / cover;
	padding-top: 70px;
	margin-top: -70px;
	pointer-events: none;

	.content__desc {
		max-width: 830px;
		pointer-events: auto;
		margin-bottom: 62px; }

	.header__count {
		color: var(--main);
		pointer-events: auto;
		gap: 160px;

		@include mobile {
			gap: 48px; } } }

.section__departs {
	background: #F3F0F3;
	padding-top: 62px;
	padding-bottom: 62px; }

.departs__content {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include mobile {
		flex-direction: column; } }

.departs__info {
	width: 35%;

	@include mobile {
		width: 100%;
		margin-bottom: 24px; }

	.section__title {
		text-align: left;
		margin-bottom: 26px;

		@include tablet {
			font-size: 30px;
			line-height: 100%;
			margin-bottom: 16px; } }

	ul {
		font-weight: 700;
		margin-top: 15px;

		li {
			padding-left: 20px;
			position: relative;

			&::after {
				position: absolute;
				left: 6px;
				top: 8px;
				content: '';
				width: 4px;
				height: 4px;
				background: #000;
				border-radius: 50%; } } } }

.departs__button {
	margin-top: 48px;

	@include mobile {
		margin-top: 24px;
		max-width: 335px;
		width: 100%; }

	&.depart-button__mob {
		display: none;

		@include mobile {
			display: flex; } }

	&.depart-button__desk {

		@include mobile {
			display: none; } } }

.departs__img {
	display: none;
	border-radius: 10px;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;

	&.active {
		display: block; } }

.departs__imgs-wrap {
	position: relative;
	width: 57%;

	@include mobile {
		width: 100%; } }

.departs__imgs {
	aspect-ratio: 1.44 / 1; }

.departs__links {
	position: absolute;
	left: 24px;
	right: 24px;
	bottom: 24px;
	display: flex;
	justify-content: center;
	gap: 24px;
	flex-wrap: wrap;

	@include mobile {
		left: 15px;
		right: 15px;
		bottom: 15px;
		gap: 16px; } }

.departs__link {
	backdrop-filter: blur(4px);
	background: rgba(255, 255, 255, 0.1);
	height: 47px;
	width: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #fff;
	border-radius: 10px;
	color: #fff;
	cursor: pointer;
	transition-duration: var(--trds);

	@include mobile {
		font-size: 12px;
		height: 32px;
		width: auto;
		padding-left: 14px;
		padding-right: 14px; }

	&:hover {
		border-color: var(--main); }

	&.active {
		background: var(--main);
		border-color: var(--main);
		color: #fff; } }

.section__lic {
	padding-top: 62px;
	padding-bottom: 62px;

	.section__title {
		margin-bottom: 48px; } }

.docs__slider-wrap {
	margin-left: -13px;
	margin-right: -13px;

	@include mobile {
		margin-left: -20px;
		margin-right: -20px; }

	.slick-track {
		display: flex; } }

.docs-item__inner {
	padding-left: 13px;
	padding-right: 13px;
	display: flex !important;
	height: auto !important;

	@include tablet {
		width: 335px;
		padding-left: 8px;
		padding-right: 8px; } }


.lic__slider-wrap {
	margin-left: -13px;
	margin-right: -13px;

	@include mobile {
		margin-left: -20px;
		margin-right: -20px; } }

.lic-slider__item {
	padding-left: 13px;
	padding-right: 13px;

	@include tablet {
		width: 335px;
		padding-left: 8px;
		padding-right: 8px; } }

.lic-slider__item-inner {
	border-radius: 10px;

	@include laptop; }

.lic-slider__img {
	display: block;
	width: 100%;
	margin-bottom: 16px; }

.doposle__slider {
	position: relative;
	border-radius: 10px; }

.section__doposle-wrap {
	width: 100%;
	max-width: 815px;
	margin: 0 auto;
	position: relative; }

.doposle__slider-wrap {
	padding-bottom: 16px;
	position: relative;
	margin-bottom: 93px;

	@include tablet {
		margin-bottom: 54px; }

	.sert-slider__arrow {
		position: absolute;
		bottom: 0px;
		left: 50%;
		margin-left: -44px;
		right: 0;
		z-index: 2;
		width: 88px;
		pointer-events: none; }

	.slider-arrow__prev {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		left: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			background: #8B658A;

			svg {
				fill: var(--white); } }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds);
			transform: rotate(180deg); } }

	.slider-arrow__next {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		right: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			background: #8B658A;

			svg {
				fill: var(--white); } }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds); } } }

.doposle-wrap__item {
	display: none;

	&.active {
		display: block; } }

.doposle__links {
	display: flex;
	gap: 8px;
	overflow-x: auto;
	width: 100%;
	padding-bottom: 8px;
	margin-bottom: 24px;
	user-select: none;
	-webkit-appearance: none;

	@include scrollbars(8px,  rgba(139,101,138,.3), #fff); }

.doposle__link {
	padding: 12px 24px;
	border-radius: 10px;
	text-decoration: none;
	white-space: nowrap;
	background: #F3F0F3;

	&.active, &:hover {
		background: var(--main);
		color: #fff; } }

.doposle-slider__item {
	text-align: center; }

.doposle__img {
	_height: 510px;
	width: 100% !important;
	display: inline-block !important;
	border-radius: 10px;

	@include mobile {
		_height: 450px; } }

.doposle__info {
	display: flex;
	flex-direction: column;
	gap: 16px;

	svg {
		margin-right: 10px; } }

.doposle-info__time {
	display: flex;

	strong {
		margin-right: 5px; }

	svg {
		stroke: var(--main);
		width: 20px;
		height: 20px;
		margin-right: 8px; } }


.doposle-info__effect {
	display: flex;

	svg {
		fill: var(--main);
		width: 20px;
		height: 20px;
		margin-right: 8px; } }

.akcii-cat__list {
	display: grid;
	gap: 34px;
	grid-template-columns: repeat(4, 1fr);
	margin-top: 50px;

	@include mobile;

	@include laptop {
		grid-template-columns: repeat(3, 1fr); }

	@include tablet {
		grid-template-columns: repeat(2, 1fr); }

	@include mobile {
		grid-template-columns: repeat(1, 1fr);
		gap: 24px;
		padding-left: 24px;
		padding-right: 24px; } }

.akcii-cat__item {
	position: relative;
	border-radius: 20px;
	overflow: hidden;

	&:hover {

		.akcii-cat__img {
			transform: scale(1.15); }

		.akcii-cat__arrow {
			background: var(--main);

			svg {
				fill: var(--white); } } }

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, rgba(52, 54, 51, 0) 0%, rgba(0, 0, 0, .85) 100%);
		opacity: .85; } }

.akcii-cat__img {
	display: block;
	width: 100%;
	transition-duration: var(--trds); }

.akcii-cat__arrow {
	position: absolute;
	background: #fff;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	position: absolute;
	top: 26px;
	right: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition-duration: var(--trds);
	z-index: 2;

	svg {
		width: 14px;
		height: 10px;
		fill: var(--main);
		transition-duration: var(--trds);
		transform: rotate(-45deg);

		@include mobile {
			_fill: var(--white); } } }

.akcii-cat__info {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 15px;
	color: #fff;
	z-index: 2; }

.akcii-cat__title {
	text-decoration: none;
	z-index: 2;
	font-size: 20px;
	font-weight: 700;
	line-height: normal;
	color: #fff; }

.akcii-cat__price {
	margin-top: 15px;
	font-size: 23px;
	line-height: 130%;
	display: flex;
	flex-wrap: wrap;
	white-space: nowrap;
	align-items: flex-end;

	span {
		margin: 0 5px; } }

.akcii-cat__price--old {
	font-size: 18px;
	text-decoration: line-through;
	line-height: normal;
	margin: 0; }

.akcii__top {
	position: relative;
	background-size: cover;
	background-position: 50% 50%;
	display: block;
	text-decoration: none;

	.container-fluid {
		position: relative;
		z-index: 2; } }

.akcii-top__desc {
		padding-top: 62px;
		padding-bottom: 62px;
		width: 100%;
		max-width: 740px; }

.akcii-top__title {
	font-family: var(--font-add);
	font-weight: 500;
	font-size: 32px;
	line-height: 100%;
	color: var(--text);

	strong {
		color: var(--main);
		font-weight: 500; } }

.akcii-top__img {
	position: absolute;
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: 50% 50%; }

.akcii-top__price {
	display: flex;
	align-items: flex-end;
	line-height: normal;
	margin-top: 32px; }

.akcii-top__price-now {
	color: var(--main);
	font-weight: 700;
	font-size: 64px;
	line-height: 60px;

	@include tablet {
		font-size: 30px;
		line-height: 100%; } }

.akcii-top__price-desc {
	font-size: 32px;
	font-weight: 700;
	margin-bottom: -8px;
	margin-left: 10px;

	@include tablet {
		font-size: 18px;
		margin-left: 2px;
		margin-bottom: 0; } }

.akcii-top__price-old {
	font-size: 32px;
	font-weight: 300;
	margin-bottom: -8px;
	margin-left: 10px;
	position: relative;

	&::after {
		content: '';
		background: var(--text);
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		height: 1px; }

	@include tablet {
		font-size: 18px;
		margin-bottom: 0; } }

.section__akcia {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@include tablet {
		flex-direction: column; } }

.akcia__slider {
	position: relative; }

.akcia__slider-wrap {
	padding-bottom: 16px;
	position: relative;
	width: 40%;

	@include tablet {
		width: 100%;
		margin-bottom: 74px; }

	.sert-slider__arrow {
		position: absolute;
		bottom: 0px;
		left: 50%;
		margin-left: -44px;
		right: 0;
		z-index: 2;
		width: 88px;
		pointer-events: none; }

	.slider-arrow__prev {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		left: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			background: #8B658A;

			svg {
				fill: var(--white); } }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds);
			transform: rotate(180deg); } }

	.slider-arrow__next {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		right: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			background: #8B658A;

			svg {
				fill: var(--white); } }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds); } } }

.akcia__desc {
	width: 55%;

	@include tablet {
		width: 100%; }

	.page__title {
		text-align: left; } }

.akcia__price {
	background: #fff;
	border: 1px solid var(--main);
	border-radius: 10px;
	padding-top: 24px;
	padding-bottom: 24px;
	margin-bottom: 40px;
	display: flex;
	flex-direction: column;
	gap: 16px; }

.akcia-price__item {
	display: flex;
	justify-content: space-between;
	padding-right: 24px;
	align-items: center;

	&:nth-child(2n-1) {
		background: #F3F0F3; } }

.akcia-price__title {
	padding: 8px 24px; }

.akcia-price__value {
	background: var(--main);
	color: var(--white);
	border-radius: 20px;
	padding: 10px 8px;
	width: 170px;
	white-space: nowrap;
	text-align: center; }

.article__more {
	padding-top: 62px;
	padding-bottom: 62px;
	background: #F3F0F3;

	.page__title {
		margin-bottom: 48px; }

	.blog__item {
		background: #fff; } }

.article__inner {
	width: 100%;
	max-width: 817px;

	h3 {
		color: var(--main); }

	img {
		display: block;
		width: 100% !important;
		height: auto !important;
		border-radius: 10px;
		margin: 24px 0 48px; }

	.page__title {
		text-align: left; }

	p {
		margin-top: 24px;

		&:first-child {
			margin-top: 0; } }

	blockquote {
		background: #F3F0F3;
		border-radius: 10px;
		padding: 24px;
		margin-bottom: 24px;
		margin-top: 24px;

		a {
			color: #888; }

		strong {
			color: var(--main);
			font-weight: 700;
			margin-bottom: 16px;
			display: inline-block; }

		ul {
			font-weight: 700;
			margin-left: 7px;

			&.navlist {
				font-weight: 400; }

			li {
				position: relative;
				padding-left: 20px;

				& + li {
					margin-top: 10px; }

				&::before {
					content: '';
					width: 4px;
					height: 4px;
					background: #000;
					border-radius: 50%;
					top: 9px;
					left: 5px;
					position: absolute; } } } } }

.article__spec {
	width: 400px;
	min-width: 400px;
	margin-left: 30px;

	@include width1200 {
		width: 350px;
		min-width: 350px; }

	@include width1100 {
		width: 300px;
		min-width: 300px; }

	@include tablet {
		display: none; } }

.article--spec {
	display: flex;
	justify-content: space-between; }

.article-spec__inner {
	padding: 24px;
	border-radius: 20px 20px 0 0;
	background: url(../images/content/blog/sale.jpg) no-repeat 100% 100%;
	height: 530px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: sticky;
	top: 30px;

	&::after {
		content: '';
		position: absolute;
		background: linear-gradient(186.47deg, #F3F0F3 31.78%, rgba(255, 255, 255, 0) 49.49%);
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 20px; } }

.article-spec__title {
	font-weight: 500;
	font-size: 50px;
	line-height: 100%;
	color: var(--main);
	font-family: var(--font-add);
	margin-bottom: 8px;
	position: relative;
	z-index: 2;

	@include width1200 {
		font-size: 40px; } }

.article-spec__intro {
	font-weight: 400;
	font-size: 20px;
	color: var(--text);
	position: relative;
	z-index: 2;

	span {
		font-weight: 700;
		color: var(--main);
		line-height: normal; } }

.article-spec__btn {
	padding-top: 11px;
	padding-bottom: 11px;
	position: relative;
	z-index: 2;
	margin-top: auto; }

.article__info {
	display: flex;
	align-items: center;
	color: #888888;
	font-size: 12px;
	margin-bottom: 18px;
	gap: 20px;

	svg {
		width: 20px;
		height: 20px;
		margin-left: 8px;

		&.icon-icon_view {
			fill: var(--main); }

		&.icon-icon_time {
			stroke: var(--main);
			stroke-width: 1.5px; } } }

.article-info__item {
	display: flex;
	align-items: center; }

.section__content-service {

	.faq__section {
		margin-top: 60px; }

	.section__dosc {
		padding: 62px 0;

		.button {
			max-width: 428px;
			margin: 0 auto;
			margin-top: 48px; } } }

.section__trust {
	background: url(../images/content/main/reviews_bg.svg) no-repeat 50% 50% var(--main);
	background-size: cover;
	padding-top: 62px;
	padding-bottom: 58px;
	color: #fff;

	.section__title {
		margin-bottom: 45px;
		color: var(--white);

		@include tablet {
			font-size: 30px;
			line-height: 100%; }

		@include mobile {
			margin-bottom: 30px; } } }

.trust__list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 30px;

	@include tablet {
		grid-template-columns: repeat(2, 1fr); }

	@include minMobile {
		grid-template-columns: repeat(1, 1fr); } }


.trust__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center; }

.trust-item__title {
	font-weight: 700;
	font-size: 16px;
	line-height: normal;
	text-align: center;
	margin-bottom: 16px;
	text-transform: uppercase; }

.trust-item__intro {
	font-size: 15px;
	line-height: 130%;
	text-align: center; }

.tags-carousel-wrapper {
	position: relative;
	display: flex;
	justify-content: center;

	&.viewScroll {
		padding-left: 46px;
		padding-right: 46px;

		.tags-arrow__prev, .tags-arrow__next {
			display: flex; }


		&:before {
			_content: '';
			position: absolute;
			top: 0;
			left: 45px;
			bottom: 38px;
			width: 25px;
			background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
			z-index: 5; }


		&:after {
			_content: '';
			position: absolute;
			top: 0;
			right: 45px;
			bottom: 38px;
			width: 25px;
			background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); } }

	.tags-arrow__prev, .tags-arrow__next {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 2;
		pointer-events: none;
		display: block;

		@include mobile {
			display: none; } }

	.tags-arrow__prev {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		left: 0px;
		top: 50%;
		margin-top: -26px;
		display: none;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		@include width1400 {
			_left: 25px; }

		@include tablet {
			margin-top: -35px; }

		&:hover {
			background: #8B658A;

			svg {
				fill: var(--white); } }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds);
			transform: rotate(180deg); } }

	.tags-arrow__next {
		width: 36px;
		height: 36px;
		border: 1px solid var(--main);
		border-radius: 50%;
		right: 0;
		background: transparent;
		position: absolute;
		right: 0px;
		top: 50%;
		margin-top: -26px;
		display: none;
		justify-content: center;
		align-items: center;
		transition-duration: var(--trds);
		cursor: pointer;
		pointer-events: auto;

		@include width1400 {
			_right: 25px; }

		@include tablet {
			margin-top: -35px; }

		&:hover {
			background: #8B658A;

			svg {
				fill: var(--white); } }

		svg {
			width: 15px;
			height: 12px;
			fill: var(--main);
			transition-duration: var(--trds); } } }


.tags-carousel {
	display: flex;
	gap: 24px;
	margin-bottom: 24px;
	margin-top: 24px;
	_flex-wrap: wrap;
	overflow: auto;
	_width: 100%;
	padding-bottom: 5px;
	position: relative;
	padding-bottom: 8px;
	margin-bottom: 24px;
	user-select: none;
	_-webkit-appearance: none;

	@include scrollbars(8px,  rgba(139,101,138,.3), #fff);

	@include tablet {
		margin-top: 0;
		margin-bottom: 18px; } }

.tags-carousel__link {
	padding: 12px 24px;
	background: #F3F0F3;
	border-radius: 10px;
	text-align: center;
	text-decoration: none;
	display: flex;
	_text-transform: uppercase;
	white-space: nowrap;

	&:hover, &.active {
		background: var(--main);
		color: #fff; } }

.section__warning {
	background: #F3F0F3;
	padding-top: 62px;
	padding-bottom: 62px; }

.warning__list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 62px;

	@include tablet {
		grid-template-columns: repeat(1, 1fr);
		gap: 40px; } }


.warning__item {

	&:first-child {
		padding-left: 0;
		border-right: 1px solid var(--main);
		padding-right: 40px;

		@include tablet {
			padding-right: 0;
			border: none;
			border-bottom: 1px solid var(--main);
			padding-bottom: 40px; } }

	p {
		margin-bottom: 16px; }

	ul {
		font-weight: 700;
		font-size: 16px;
		color: var(--text);
		display: flex;
		flex-direction: column;
		gap: 16px;

		li {
			padding-left: 20px;
			position: relative;

			&::after {
				content: '';
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: #000;
				position: absolute;
				left: 5px;
				top: 8px; } } } }

.warning-list__title {
	font-family: var(--font-add);
	color: var(--main);
	margin-bottom: 16px;
	font-weight: 500;
	font-size: 32px;
	line-height: 100%;

	@include mobile {
		font-size: 30px; } }

.price-item__inner {
	padding-top: 62px;
	padding-bottom: 62px;

	.price_content {
		border: 1px solid var(--main);
		border-radius: 10px;
		padding: 24px 0px; }

	.price__list {

		.price_content {

			.price_title_box {
				margin-bottom: 25px;
				color: #000; }

			.price_box_one {

				.price_title_box {
					padding-right: 24px;
					padding-left: 24px;
					margin-bottom: 25px;

					@include mobile {
						padding-left: 16px; } }

				.price_text {
					padding-left: 24px;
					padding-right: 24px;
					padding-top: 8px;
					padding-bottom: 8px;
					padding-right: 0;
					display: flex;

					@include mobile {
						padding-left: 16px; }

					&::after {
						content: '';
						height: 1px;
						border-bottom: 1px dotted #343633;
						flex: 1 0;
						margin-left: 14px;
						margin-top: 12px; }

					&:nth-child(2n-1) {
						background: #F3F0F3; }

					&:nth-child(2n) {
						padding-top: 16px;
						padding-bottom: 16px; } } }

			.price_box_two {

				@include mobile {
					min-width: 80px; }

				.price_text {
					padding-top: 8px;
					padding-bottom: 8px; }

				&:nth-child(2) {
					display: block;
					color: #000; }

				.price_ray {
					text-decoration: line-through;
					padding-top: 8px;
					padding-bottom: 8px;
					display: flex;
					align-items: center;
					text-align: center;
					justify-content: center;

					@include mobile {
						padding-left: 16px;
						padding-right: 16px; }


					&:nth-child(2n-1) {
						background: #F3F0F3; }

					&:nth-child(2n) {
						padding-top: 16px;
						padding-bottom: 16px; } }

				.pr {
					padding-top: 8px;
					padding-bottom: 8px;
					padding-left: 0;
					display: flex;
					align-items: center;
					padding-right: 24px;

					@include mobile {
						padding-right: 16px; }

					& > span {
						display: block;
						padding: 8px 16px;
						background: var(--main);
						border-radius: 20px;
						color: #fff;
						margin: -8px 0;
						width: 100%; }

					&:nth-child(2n-1) {
						background: #F3F0F3; }

					&:nth-child(2n) {
						padding-top: 16px;
						padding-bottom: 16px; } } } } } }

.service__content {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	padding-bottom: 64px;
	gap: 62px;
	min-width: 0;
	width: 100%;

	@include mobile {
		padding-bottom: 0; }

	@include mobile {
		grid-template-columns: repeat(1,1fr);
		gap: 24px; }

	.doposle__wrap {
		display: block;
		width: 100%;
		min-width: 0; } }

.service__doposle {
	display: flex;
	min-width: 0;

	@include mobile {
		order: 2; }

	.doposle-slider__item {
		overflow: hidden; } }

.service-info__content {
	margin-bottom: 20px;

	@include tablet {
		margin-bottom: 0; }

	div {
		margin-bottom: 16px; }

	p {
		margin-bottom: 16px; }

	ul {
		color: var(--text);
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-bottom: 16px;

		li {
			padding-left: 20px;
			position: relative;

			&::after {
				content: '';
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: #000;
				position: absolute;
				left: 5px;
				top: 8px; } } }

	.inf_link {
		margin-left: 10px;
		font-weight: 700;
		margin-bottom: 0;

		& + .inf_link {
			margin-top: 6px; }

		.in_link {
			display: inline-block;
			margin-left: 10px; }

		&::before {
			content: '•';
			margin-right: 10px; } } }

.service__info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	h2 {
		font-weight: 500;
		font-size: 32px;
		line-height: 100%;
		color: var(--main);
		font-family: var(--font-add);

		@include mobile {
			font-size: 30px; } }

	.page__title {
		text-align: left; } }

.revi {
	_display: flex;
	_flex-direction: column;
	_align-items: center;
	min-width: 0; }


.simplesearch-results {
	text-align: center;
	margin-bottom: 16px;
	font-weight: 700; }

.simplesearch-paging {
	text-align: center;
	margin-bottom: 16px;
	font-weight: 700; }

.simplesearch-results-list {
	display: flex;
	flex-direction: column;
	gap: 16px;

	h3 {
		margin-bottom: 5px; }


	& + .simplesearch-paging {
		margin-top: 16px;
		margin-bottom: 0; } }

.lips_text {

	div {
		margin-bottom: 16px; }

	p {
		margin-bottom: 16px; }

	ul {
		color: var(--text);
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-bottom: 16px;

		li {
			padding-left: 20px;
			position: relative;

			&::after {
				content: '';
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: #000;
				position: absolute;
				left: 5px;
				top: 8px; } }

		&.smas-lifting-page-grid1 {
			display: grid;
			grid-template-columns: repeat(2,1fr);
			gap: 16px;

			@include mobile {
				grid-template-columns: repeat(1,1fr); }

			li:after {
				display: none; } }

		&.smas-lifting-page-grid2 {
			display: grid;
			grid-template-columns: repeat(2,1fr);
			gap: 16px;

			@include mobile {
				grid-template-columns: repeat(1,1fr); }

			li {
				padding-left: 0; }

			li:after {
				display: none; } }

		&.smas-lifting-page-grid3 {
			display: grid;
			grid-template-columns: repeat(2,1fr);
			gap: 16px;

			@include mobile {
				grid-template-columns: repeat(1,1fr); }

			li {
				padding-left: 0; }

			li:after {
				display: none; }

			.smas-lifting-page-grid3__item.grid3-item--last {
				font-family: var(--font);
				box-shadow: none; } } } }

.head_banner_button {
	margin-top: 28px; }

.head_banner_container {
	padding: 0 0 15px 0 !important;

	p {
		width: 100%; } }

.reviews__slider--inner {
	min-width: 0;
	margin-bottom: 70px;

	.slider-arrow__prev {
		border: 1px solid var(--main);

		&:hover {

			svg {
				fill: var(--white); } }

		svg {
			fill: var(--main); } }

	.slider-arrow__next {
		border: 1px solid var(--main);

		&:hover {

			svg {
				fill: var(--white); } }

		svg {
			fill: var(--main); } } }
